import { Page } from "@react-pdf/renderer";
import React from "react";

const _style = {
  paddingBottom: 16,
  paddingTop: 10,
  paddingHorizontal: 10,
};

export const A4Page = ({ children, orientation, style = {} }) => {
  return (
    <Page size="A4" style={{ ..._style, ...style }} orientation={orientation}>
      {children}
    </Page>
  );
};
