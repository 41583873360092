import React from "react";
import { Table } from "../../../../components/Table";

export const ImpressaoEtiquetasGrid = ({ data, setSelected }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nro_pedido",
      text: "Nº do Pedido",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "id_user__nome",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "descricao_ped",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      paginated
      pageSize={50}
      clearSelectionOnUpdate
    />
  );
};
