import React from "react";
import { Table } from "../../../../components/Table";

export const ImpressaoEtiquetasManualGrid = ({
  data,
  setSelected,
  setSelectedAll,
}) => {
  const columns = [
    {
      dataField: "seq",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nro_pedido",
      text: "Nº do Pedido",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "tipo",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "cliente_final",
      text: "Cliente Final",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "nome_porta",
      text: "Nome Porta",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "largura_porta",
      text: "Largura",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "altura_porta",
      text: "Altura",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "material",
      text: "Material",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "lado",
      text: "Lado",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "cor",
      text: "Cor",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "marco",
      text: "Marco",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "fechadura",
      text: "Fechadura",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "referencia_fecha",
      text: "Referência",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "tipo_fecha",
      text: "Tp Fechadura",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "qtd_vista_fixa",
      text: "Vista F.",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "qtd_vista_regulavel",
      text: "Vista R.",
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <Table
      data={data}
      keyField="uuid"
      columns={columns}
      onSelect={setSelected}
      onSelectAll={setSelectedAll}
      multiselect
      fixedSize={false}
      paginated
      pageSize={50}
      clearSelectionOnUpdate
    />
  );
};
