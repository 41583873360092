import React, { forwardRef, useImperativeHandle, useState } from "react";
import FormButton from "./FormButton";
import { MODAL_ACTIONS } from "../coreUtils";
import { ModalExcluir } from "./ModalExcluir";
import { ModalBase } from "./ModalBase";
import { useRef } from "react";
import { apiGet, apiPost, apiPut } from "../api";

export const ModalCadastro = forwardRef(
  (
    {
      title = "Sem Título",
      size = "lg",
      onOpen,
      onClose,
      fetchData,
      submitPayload,
      headerCheck,
      routes,
      routesBase,
      number = "Sem Número",
      deleteModalNumber = "Sem Número",
      deleteMessage,
      canDelete = true,
      deleteHint,
      selected,
      buttonsPadded = true,
      buttonsDivClassName = "",
      notifyEvent,
      addButtonText = "Incluir",
      loadingFetch,
      noDelete = false,
      autoTitle = true,
      hideEditButton,
      hideDeleteButton,
      onKeyDown,
      paramsName,
      children,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [action, setAction] = useState(MODAL_ACTIONS.ADD);
    const [loading, setLoading] = useState(false);
    const [loadingFetchInternal, setLoadingFetchInternal] = useState(false);
    const deleteModalRef = useRef(null);

    const innerFetchData = async () => {
      setLoadingFetchInternal(true);
      const data = await apiGet(
        routesBase ? `${routesBase}/buscar/${selected}/` : routes.get
      );
      setLoadingFetchInternal(false);
      fetchData(data);
    };

    const toggle = async (action) => {
      if (!isOpen && action === MODAL_ACTIONS.EDIT && fetchData) {
        await innerFetchData();
      }
      setAction(typeof action === "number" ? action : MODAL_ACTIONS.ADD);
      setIsOpen(!isOpen);
    };

    const onBeforeOpen = (params) => {
      if (onOpen) onOpen(action, params);
    };

    const onBeforeClose = () => {
      if (onClose) onClose(action);
    };

    const handleSubmit = async () => {
      const payload = submitPayload(action);

      if (payload) {
        setLoading(true);
        if (
          action === MODAL_ACTIONS.ADD
            ? await apiPost(
                routesBase ? `${routesBase}/incluir/` : routes.post,
                payload
              )
            : await apiPut(
                routesBase ? `${routesBase}/alterar/` : routes.put,
                payload
              )
        ) {
          notifyEvent(action);
          toggle(action);
        }
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      toggle: (action) => {
        if (action === MODAL_ACTIONS.DELETE && !noDelete) {
          deleteModalRef.current.toggle();
        } else {
          toggle(action);
        }
      },
    }));

    return (
      <>
        <FormButton
          padded={buttonsPadded}
          divClassName={buttonsDivClassName}
          md="auto"
          color="info"
          onClick={() => toggle(MODAL_ACTIONS.ADD)}
        >
          {addButtonText}
        </FormButton>
        {!hideEditButton && (
          <FormButton
            padded={buttonsPadded}
            divClassName={buttonsDivClassName}
            md="auto"
            color="warning"
            disabled={[0, null, undefined].includes(selected)}
            onClick={() => toggle(MODAL_ACTIONS.EDIT)}
            loading={loadingFetch || loadingFetchInternal}
            id="bt-alterar"
          >
            Alterar
          </FormButton>
        )}
        {!noDelete && (
          <ModalExcluir
            title={title}
            number={deleteModalNumber}
            selected={selected}
            route={
              routesBase ? `${routesBase}/excluir/${selected}/` : routes.delete
            }
            notifyEvent={notifyEvent}
            buttonPadded={buttonsPadded}
            message={deleteMessage}
            buttonDivClassName={buttonsDivClassName}
            hideButton={hideDeleteButton}
            canDelete={canDelete}
            deleteHint={deleteHint}
            ref={deleteModalRef}
          />
        )}
        <ModalBase
          isOpen={isOpen}
          size={size}
          title={
            <>
              {autoTitle &&
                (action === MODAL_ACTIONS.ADD
                  ? "Inclusão de "
                  : "Alteração de ") + " "}
              {title}
            </>
          }
          headerCheck={headerCheck}
          number={number}
          onConfirm={handleSubmit}
          loadingConfirm={loading}
          toggle={toggle}
          onKeyDown={onKeyDown}
          onBeforeOpen={onBeforeOpen}
          onBeforeClose={onBeforeClose}
          paramsName={paramsName}
        >
          {children}
        </ModalBase>
      </>
    );
  }
);
