import React from "react";

const ModalHeaderActionArea = ({ children }) => {
  return (
    <div
      className="position-absolute"
      style={{
        right: "3.8rem",
        top: "1.09rem",
        display: "flex",
        alignItems: "baseline",
      }}
    >
      {children}
    </div>
  );
};
export default ModalHeaderActionArea;
