import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const _styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "stretch",
    width: "100%",
  },
  text: {
    fontSize: "8",
    fontFamily: "Open Sans",
  },
});

const TextRow = ({ columns = [], style, fixed = false, wrap = true }) => {
  return (
    <View style={[_styles.wrapper, style]} fixed={fixed} wrap={wrap}>
      {columns.map((item) => (
        <Text
          style={[
            _styles.text,
            { width: `${(100 / 12) * item.col}%` },
            item.style,
          ]}
        >
          {item.text}
        </Text>
      ))}
    </View>
  );
};

export { TextRow };
