import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

export const IconButton = ({
  icon: Icon,
  size = 18,
  color,
  onClick,
  disabled,
  hint,
  id,
  className,
}) => {
  const [internalId] = useState(
    id ?? "ib-" + Math.floor(Math.random() * Date.now())
  );

  return (
    <>
      <Icon
        size={size}
        onClick={!disabled ? (e) => onClick(e) : () => {}}
        color={color}
        id={internalId}
        style={{ cursor: !disabled ? "pointer" : "default" }}
        className={className}
      />
      {hint && (
        <UncontrolledTooltip target={internalId}>{hint}</UncontrolledTooltip>
      )}
    </>
  );
};
