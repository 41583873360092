import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { rawAPIUrl } from "../../coreUtils";

const _styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    marginBottom: 5,
    borderBottom: "0.5px solid #000",
    paddingBottom: "5px",
  },
  nomeEmpresa: { fontFamily: "Open Sans Bold", fontSize: 9 },
  logo: {
    maxHeight: "100%",
    maxWidth: 75,
    objectFit: "contain",
    alignSelf: "center",
    borderRight: "0.5px solid #000",
    padding: "10 10",
    marginRight: "10",
  },
  infoEmpresa: { fontFamily: "Open Sans", fontSize: 8 },
  row: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
});

export const ReportHeader = ({ dataIni, dataFim, title }) => {
  const dataAgora = moment(new Date());

  const emissao = `Emitido em ${dataAgora.format(
    "DD/MM/YYYY"
  )} às ${dataAgora.format("HH:mm")}`;

  const periodo =
    dataIni && dataFim
      ? `Referente ao período de ${
          dataIni instanceof Date
            ? moment(dataIni).format("DD/MM/YYYY")
            : dataIni
        } até ${
          dataFim instanceof Date
            ? moment(dataFim).format("DD/MM/YYYY")
            : dataFim
        }`
      : false;

  return (
    <View style={_styles.wrapper}>
      <Image style={_styles.logo} src={rawAPIUrl("/cadastro/empresa/logo/")} />
      <View style={{ width: "100%" }}>
        <View style={_styles.row}>
          <Text style={_styles.nomeEmpresa}>
            {localStorage.getItem("nome_empresa")}
          </Text>
          {periodo && (
            <Text
              style={{
                fontFamily: "Open Sans",
                fontSize: 8,
                textAlign: "right",
              }}
            >
              {emissao}
            </Text>
          )}
        </View>
        <Text style={_styles.infoEmpresa}>
          {localStorage.getItem("ender_empresa")}
        </Text>
        <Text style={_styles.infoEmpresa}>
          {localStorage.getItem("compl_empresa")}
        </Text>
        <Text style={_styles.infoEmpresa}>
          CNPJ: {localStorage.getItem("cnpj_empresa")}
        </Text>
        <View style={_styles.row}>
          <Text style={_styles.infoEmpresa}>
            Telefone(s):{" "}
            {localStorage.getItem("fone_empresa") +
              (localStorage.getItem("fone2_empresa") !== "null"
                ? " " + localStorage.getItem("fone2_empresa")
                : "")}
          </Text>
          {title && (
            <Text
              style={{
                fontFamily: "Open Sans SemiBold Italic",
                fontSize: 10,
                textTransform: "uppercase",
              }}
            >
              {title}
            </Text>
          )}
          <Text style={_styles.infoEmpresa}>{periodo ? periodo : emissao}</Text>
        </View>
      </View>
    </View>
  );
};
