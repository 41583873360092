import React from "react";
import FormButton from "./FormButton";

export const BotaoPesquisar = ({
  md = "auto",
  padded = true,
  loading,
  onClick,
  className = "",
  divClassName = "",
  style = {},
  disabled,
  disabledHint,
  id = "bt-pesquisar",
}) => {
  return (
    <FormButton
      color="secondary"
      onClick={onClick}
      md={md}
      loading={loading}
      padded={padded}
      className={className}
      divClassName={divClassName}
      style={style}
      disabled={loading || disabled}
      disabledHint={disabledHint}
      id={id}
    >
      Pesquisar
    </FormButton>
  );
};
