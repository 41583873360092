import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroUsuarioModal } from "./components/CadastroUsuarioModal";
import { UsuarioGrid } from "./components/UsuarioGrid";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../coreUtils";
import {
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../components";
import { apiGet } from "../../../api";
import { AlterarSenhaUsuarioModal } from "./components/AlterarSenhaUsuarioModal";
import { DefinirPercentuaisUsuarioModal } from "./components/DefinirPercentuaisUsuarioModal";

export const Usuario = () => {
  const [pista, setPista] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const [alterarSenhaIsOpen, setAlterarSenhaIsOpen] = useState(false);
  const [definirPercentuaisIsOpen, setDefinirPercentuaisIsOpen] =
    useState(false);

  const buscarDados = async (p) => {
    const ret = await apiGet("/cadastro/user/listar/", {
      pista: pista,
      somente_ativos: somenteAtivos,
    });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const alterarSenhaUsuario = (idUser) => {
    setSelected(idUser);
    setTimeout(() => {
      setAlterarSenhaIsOpen(true);
    }, 1);
  };

  const definirPercentuaisUsuario = (idUser) => {
    setSelected(idUser);
    setTimeout(() => {
      setDefinirPercentuaisIsOpen(true);
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Usuários" number="0001">
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroUsuarioModal selected={selected} notifyEvent={notifyEvent} />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={setSomenteAtivos}
          />
          <AlterarSenhaUsuarioModal
            selected={selected}
            notifyEvent={notifyEvent}
            isOpen={alterarSenhaIsOpen}
            toggle={() => setAlterarSenhaIsOpen(!alterarSenhaIsOpen)}
          />
          <DefinirPercentuaisUsuarioModal
            selected={selected}
            notifyEvent={notifyEvent}
            isOpen={definirPercentuaisIsOpen}
            toggle={() =>
              setDefinirPercentuaisIsOpen(!definirPercentuaisIsOpen)
            }
          />
        </Row>
      </Card>
      <Card body>
        <UsuarioGrid
          dados={dados}
          setSelected={setSelected}
          alterarSenhaUsuario={alterarSenhaUsuario}
          definirPercentuaisUsuario={definirPercentuaisUsuario}
        />
      </Card>
    </PageContainer>
  );
};
