import React from "react";
import { useState } from "react";
import {
  DragDropImagem,
  ModalCadastro,
  NumberInput,
  TextInput,
} from "../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../coreUtils";

export const CadastroGenericoModal = ({ selected, notifyEvent }) => {
  const [nome, setNome] = useState("");
  const [fotoBase64, setFotoBase64] = useState("");

  const limparDados = () => {
    setNome("");
    setFotoBase64("");
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setFotoBase64(data.foto_base64);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      foto_base64: fotoBase64,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Negativo"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/negativo"
      number="0008_1"
      deleteModalNumber="0008_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={80}
        />
      </Row>
      <DragDropImagem
        width="100%"
        height="400px"
        divClassName="mt-2"
        value={fotoBase64}
        onChange={setFotoBase64}
      />
    </ModalCadastro>
  );
};
