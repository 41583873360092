import React from "react";
import { useState } from "react";
import {
  ModalCadastro,
  NumberInput,
  RadioGroup,
  TextInput,
  IntegerInput,
  AsyncComboBox,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Row } from "reactstrap";

const tiposDobr = [
  { label: "Sobreposta", value: "SOBRE" },
  { label: "Embutida", value: "EMBUT" },
];

export const CadastroDobradicaModal = ({ selected, notifyEvent }) => {
  const [nome, setNome] = useState("");
  const [tipoDobr, setTipoDobr] = useState(tiposDobr[0].value);
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setNome("");
    setTipoDobr(tiposDobr[0].value);
    setVlrCusto(0);
    setVlrPreco(0);
    setIdIntegracao(0);
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setTipoDobr(data.tipo_dobr);
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      tipo_dobr: tipoDobr,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Dobradiça"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/dobradica"
      number="0003_1"
      deleteModalNumber="0003_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={80}
        />
        <RadioGroup
          label="Tipo"
          options={tiposDobr}
          value={tipoDobr}
          onChange={setTipoDobr}
        />
      </Row>
      <Row>
        <NumberInput label="Custo" value={vlrCusto} onChange={setVlrCusto} />
        <NumberInput label="Preco" value={vlrPreco} onChange={setVlrPreco} />
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
        />
      </Row>
    </ModalCadastro>
  );
};
