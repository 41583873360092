import React from "react";
import { formatNumber } from "../../../../coreUtils";
import { Table, TableDelete } from "../../../../components";

export const VinculacaoGrupoDescontoClienteGrid = ({
  data,
  excluirVinculacao,
}) => {
  const columns = [
    {
      dataField: "id_user__nome",
      text: "Nome Usuário",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "perc_desc_acre",
      text: "% Desconto/Acréscimo",
      align: "right",
      headerAlign: "right",
      formatter: (c) => `${formatNumber(c, true, 2)} %`,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (_, row) => (
        <TableDelete onClick={() => excluirVinculacao(row.id)} />
      ),
    },
  ];

  return <Table data={data} columns={columns} paginated />;
};
