import React, { useState } from "react";
import { Info } from "react-feather";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

export const HintLabel = ({
  label,
  hintText,
  enabled = true,
  tooltipPlacement,
}) => {
  const [id] = useState(`hint-${Math.floor(Math.random() * Date.now())}`);
  return (
    <>
      <span>{label}</span>
      {enabled && hintText && (
        <>
          <Info
            size={12}
            id={id}
            style={{ marginTop: "-2px", marginLeft: "3px" }}
          />
          <UncontrolledTooltip target={id} placement={tooltipPlacement}>
            <div style={{ textAlign: "left" }}>{hintText}</div>
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};
