import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import DateTime from "react-datetime";
import InputMask from "react-input-mask";
import { Col, FormGroup, Label } from "reactstrap";
import { handleFocus } from "../coreUtils";

export const DatePicker = ({
  value,
  onChange,
  label,
  md = "auto",
  name,
  autoFocus,
  disabled,
  inputId,
  minDate,
  maxDate,
}) => {
  const [canShowCalendar, setCanShowCalendar] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [valid, setValid] = useState(true);
  const [internalId] = useState(
    inputId ?? "dp-" + Math.floor(Math.random() * Date.now())
  );
  const ref = useRef(null);

  const __renderInput = (props, _, __) => {
    return (
      <InputMask mask="99/99/9999" maskChar={null} {...props} name={name} />
    );
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const onBlur = (v) => {
    if (typeof v === "string") {
      let fullDate = moment(v, "DD/MM/YYYY");
      if (fullDate.isValid()) {
        if (
          fullDate.format("YYYY-MM-DD") !==
          moment(internalValue).format("YYYY-MM-DD")
        ) {
          setInternalValue(fullDate);
          setValid(true);
          if (onChange) onChange(fullDate);
        }
      } else if (v !== "") {
        setValid(false);
      }
    }
  };

  const onChangeInternal = (v) => {
    setInternalValue(v);
    if (onChange) onChange(v);
  };

  const validation = (currentDate) => {
    const minDateValid =
      minDate instanceof Date ? currentDate?.isAfter(moment(minDate)) : true;
    const maxDateValid =
      maxDate instanceof Date ? currentDate?.isBefore(moment(maxDate)) : true;

    return minDateValid && maxDateValid;
  };

  return (
    <Col md={md}>
      <FormGroup>
        <Label for={internalId}>{label}</Label>
        <DateTime
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          value={internalValue}
          onChange={onChangeInternal}
          renderInput={__renderInput}
          isValidDate={
            minDate instanceof Date || maxDate instanceof Date
              ? validation
              : undefined
          }
          inputProps={{
            id: internalId,
            className: `form-control${valid ? "" : " is-invalid"}`,
            autoFocus: autoFocus,
            disabled: disabled,
            onBlur: (e) =>
              ref.current?.state.open === false && onBlur(e.target.value),
            onKeyDown: (e) => {
              handleFocus(e);
              if (["Enter", "ArrowUp"].includes(e.key))
                ref.current.closeCalendar();
            },
            onMouseDown: () =>
              !ref.current?.state.open && setCanShowCalendar(true),
            onMouseUp: () =>
              ref.current?.state.open && setCanShowCalendar(false),
            onFocus: () =>
              ref.current?.state.open &&
              !canShowCalendar &&
              ref.current.closeCalendar(),
          }}
          closeOnSelect
          onBlur={onBlur}
          onFocus={() => setValid(true)}
          ref={ref}
        />
      </FormGroup>
    </Col>
  );
};
