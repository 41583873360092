import React from "react";
import { useState } from "react";
import {
  ModalCadastro,
  NumberInput,
  IntegerInput,
  TextInput,
  AsyncComboBox,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Row } from "reactstrap";

export const CadastroBobinaModal = ({ selected, notifyEvent }) => {
  const [descricao, setDescricao] = useState("");
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setDescricao("");
    setVlrCusto(0);
    setVlrPreco(0);
    setIdIntegracao(0);
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      descricao: descricao,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Bobina"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/bobina"
      number="0003_1"
      deleteModalNumber="0003_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={descricao}
          onChange={setDescricao}
          maxLength={80}
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Custo"
          value={vlrCusto}
          onChange={setVlrCusto}
        />
        <NumberInput
          md={4}
          label="Preco"
          value={vlrPreco}
          onChange={setVlrPreco}
        />
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
        />
      </Row>
    </ModalCadastro>
  );
};
