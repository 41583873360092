import React from "react";
import { useState } from "react";
import {
  AsyncComboBox,
  ModalCadastro,
  NumberInput,
  PasswordInput,
  TextInput,
  DragDropImagem,
  NicknameInput,
  IntegerInput,
  FormCheckbox,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Col, Row } from "reactstrap";

export const CadastroUsuarioModal = ({ selected, notifyEvent }) => {
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [idCidade, setIdCidade] = useState(0);
  const [idCliente, setIdCliente] = useState(null);
  const [markup, setMarkup] = useState(0);
  const [logoBase64, setLogoBase64] = useState("");
  const [telefone, setTelefone] = useState("");
  const [oferecerKitEconomico, setOferecerKitEconomico] = useState(false);

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setLogin("");
    setSenha("");
    setIdCliente(null);
    setIdCidade(0);
    setMarkup(0);
    setLogoBase64("");
    setTelefone("");
    setOferecerKitEconomico(false);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setIdCidade(data.id_cidade);
    setIdCliente(data.id_cliente);
    setMarkup(parseFloat(data.markup));
    setLogin(data.login);
    setSenha(data.password);
    setAtivo(data.ativo);
    setLogoBase64(data.logo_base64 ?? "");
    setTelefone(data.telefone ?? "");
    setOferecerKitEconomico(data.oferecer_kit_economico);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      login: login,
      id_cidade: idCidade,
      markup: markup,
      logo_base64: logoBase64,
      telefone: telefone,
      id_cliente: idCliente,
      oferecer_kit_economico: oferecerKitEconomico,
    };

    return action === MODAL_ACTIONS.ADD
      ? { senha: senha, ...payload }
      : { id: selected, ativo: ativo, ...payload };
  };

  const onOpen = (action) => setAction(action);

  return (
    <ModalCadastro
      title="Usuário"
      size="lg"
      onOpen={onOpen}
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      routesBase="/cadastro/user"
      number="0001_1"
      deleteModalNumber="0001_2"
      selected={selected}
      notifyEvent={notifyEvent}
      noDelete
    >
      <Row className="no-gutters">
        <Col md={8} className="no-gutters">
          <Row>
            <TextInput
              md={12}
              label="Nome"
              value={nome}
              onChange={setNome}
              maxLength={80}
            />
          </Row>
          <Row>
            <NicknameInput
              md={6}
              label="Login"
              value={login}
              onChange={setLogin}
              maxLength={80}
              upperCase={false}
            />
            {action === MODAL_ACTIONS.ADD && (
              <PasswordInput
                md={6}
                label="Senha"
                value={senha}
                onChange={setSenha}
                maxLength={128}
              />
            )}
          </Row>
          <Row>
            <TextInput
              md={6}
              label="Fone"
              value={telefone}
              onChange={setTelefone}
              maxLength={18}
            />
            <AsyncComboBox
              md={6}
              label="Cidade"
              isConcatField
              defaultOptions
              concatModelName="cidade"
              value={idCidade}
              onChange={setIdCidade}
            />
          </Row>
          <Row>
            <NumberInput
              md={3}
              label="Markup"
              value={markup}
              onChange={setMarkup}
            />
            <IntegerInput
              label="Código Cliente"
              hint={"Informe o código do cadastro do cliente no sistema Skill"}
              md={4}
              value={idCliente}
              onChange={setIdCliente}
            />
            <FormCheckbox
              label="Oferecer Kit Econômico"
              checked={oferecerKitEconomico}
              onChange={() => setOferecerKitEconomico(!oferecerKitEconomico)}
            />
          </Row>
        </Col>
        <DragDropImagem
          placeholder="Adicionar Logo"
          divClassName="ml-auto"
          width="30%"
          height="220px"
          value={logoBase64}
          onChange={setLogoBase64}
        />
      </Row>
    </ModalCadastro>
  );
};
