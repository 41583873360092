import FilterServiceV2 from "../FiltersServiceV2";

const ComboBoxService = {
  fetchOptions: async (modelName, filters = {}) => {
    const searchParams = new URLSearchParams(filters);
    return FilterServiceV2.fetch(modelName, searchParams.toString());
  },
};

export default ComboBoxService;
