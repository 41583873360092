import { Grid } from "react-feather";
import { Cantoneira } from "../../pages/cadastro/cantoneira/Cantoneira";
import { Usuario } from "../../pages/cadastro/usuario/Usuario";
import { Cidade } from "../../pages/cadastro/cidade/Cidade";
import { Dobradica } from "../../pages/cadastro/dobradica/Dobradica";
import { UsinagemEspecial } from "../../pages/cadastro/usinagem_especial/UsinagemEspecial";
import { Fechadura } from "../../pages/cadastro/fechadura/Fechadura";
import { Marco } from "../../pages/cadastro/marco/Marco";
import { FolhaPorta } from "../../pages/cadastro/folha_porta/FolhaPorta";
import { Negativo } from "../../pages/cadastro/negativo/Negativo";
import { CorAcabamento } from "../../pages/cadastro/cor_acabamento/CorAcabamento";
import { Vista } from "../../pages/cadastro/vista/Vista";
import { Batente } from "../../pages/cadastro/batente/Batente";
import { CaixaTrilho } from "../../pages/cadastro/caixa_trilho/CaixaTrilho";
import { Puxador } from "../../pages/cadastro/puxador/Puxador";
import { GrupoDesconto } from "../../pages/cadastro/grupo_desconto/GrupoDesconto";
import { FitaBorda } from "../../pages/cadastro/fita_borda/FitaBorda";
import { Borracha } from "../../pages/cadastro/borracha/Borracha";
import { Bobina } from "../../pages/cadastro/bobina/Bobina";
import { Cola } from "../../pages/cadastro/cola/Cola";
import { Espacador } from "../../pages/cadastro/espacador/Espacador";
import { Rodape } from "../../pages/cadastro/rodape/Rodape";
import { Grelha } from "../../pages/cadastro/grelha/Grelha";
import { TrilhoPorta } from "../../pages/cadastro/trilho_porta/TrilhoPorta";
import { FechoUnha } from "../../pages/cadastro/fecho_unha/FechoUnha";

const cadastroRoutes = {
  path: "/cadastro",
  name: "Cadastro",
  icon: Grid,
  children: [
    {
      path: "/cadastro/batente",
      name: "Batente de Correr",
      component: Batente,
    },
    {
      path: "/cadastro/bobina",
      name: "Bobina",
      component: Bobina,
    },
    {
      path: "/cadastro/borracha",
      name: "Borracha",
      component: Borracha,
    },
    {
      path: "/cadastro/caixa_trilho",
      name: "Caixa de Trilho",
      component: CaixaTrilho,
    },
    {
      path: "/cadastro/cantoneira",
      name: "Cantoneira",
      component: Cantoneira,
    },
    {
      path: "/cadastro/cidade",
      name: "Cidade",
      component: Cidade,
    },
    {
      path: "/cadastro/cola",
      name: "Cola",
      component: Cola,
    },
    {
      path: "/cadastro/cor",
      name: "Cor",
      component: CorAcabamento,
    },
    {
      path: "/cadastro/dobradica",
      name: "Dobradiça",
      component: Dobradica,
    },
    {
      path: "/cadastro/espacador",
      name: "Espaçador",
      component: Espacador,
    },
    {
      path: "/cadastro/fechadura",
      name: "Fechadura",
      component: Fechadura,
    },
    {
      path: "/cadastro/fecho_unha",
      name: "Fecho Unha",
      component: FechoUnha,
    },
    {
      path: "/cadastro/fita_borda",
      name: "Fita de Borda",
      component: FitaBorda,
    },
    {
      path: "/cadastro/folha_porta",
      name: "Folha de Porta",
      component: FolhaPorta,
    },
    {
      path: "/cadastro/grelha",
      name: "Grelha",
      component: Grelha,
    },
    {
      path: "/cadastro/grupo_desconto",
      name: "Grupo de Desconto",
      component: GrupoDesconto,
    },
    {
      path: "/cadastro/jogo_marco",
      name: "Jogo de Marco",
      component: Marco,
    },
    {
      path: "/cadastro/puxador",
      name: "Puxador",
      component: Puxador,
    },
    {
      path: "/cadastro/rodape",
      name: "Rodapé",
      component: Rodape,
    },
    {
      path: "/cadastro/negativo",
      name: "Negativo",
      component: Negativo,
    },
    {
      path: "/cadastro/trilho_porta",
      name: "Trilho de Porta",
      component: TrilhoPorta,
    },
    {
      path: "/cadastro/usinagem_especial",
      name: "Usinagem Especial",
      component: UsinagemEspecial,
    },
    {
      path: "/cadastro/usuario",
      name: "Usuário",
      component: Usuario,
    },
    {
      path: "/cadastro/vista",
      name: "Vista",
      component: Vista,
    },
  ],
};

export default cadastroRoutes;
