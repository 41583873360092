import React, { FC, useState } from "react";
import { ModalBase } from "../ModalBase";
import { TabContent, Nav, NavLink, TabPane } from "reactstrap";
import classNames from "classnames";
import AbaConfigEtiquetas from "./abas/AbaConfigEtiquetas.tsx";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

enum Abas {
  Etiquetas,
}

export const ConfigModal: FC<Props> = ({ isOpen, toggle }) => {
  const [abaAtiva, setAbaAtiva] = useState<Abas>(Abas.Etiquetas);
  return (
    <ModalBase isOpen={isOpen} toggle={toggle} title="Configurações do Sistema">
      <Nav tabs>
        <NavLink
          className={classNames({ active: abaAtiva === Abas.Etiquetas })}
          onClick={() => setAbaAtiva(Abas.Etiquetas)}
        >
          Etiquetas
        </NavLink>
      </Nav>
      <TabContent activeTab={abaAtiva} className="px-2 pt-3">
        <TabPane tabId={Abas.Etiquetas}>
          <AbaConfigEtiquetas />
        </TabPane>
      </TabContent>
    </ModalBase>
  );
};
