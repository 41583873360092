import React from "react";
import { useState } from "react";
import { ModalCadastro, TextInput } from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Row } from "reactstrap";

export const CadastroGrupoDescontoModal = ({ selected, notifyEvent }) => {
  const [descricao, setDescricao] = useState("");

  const limparDados = () => {
    setDescricao("");
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
  };

  const submitPayload = (action) => {
    const payload = {
      descricao: descricao,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_grupo: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Grupo de Desconto"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/grupo_desconto"
      number="0003_1"
      deleteModalNumber="0003_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Descrição"
          value={descricao}
          onChange={setDescricao}
          maxLength={120}
        />
      </Row>
    </ModalCadastro>
  );
};
