import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { handleFocus } from "../coreUtils";

const NicknameInput = forwardRef(
  (
    {
      md,
      mdInput = 12,
      label,
      name,
      onChange,
      value,
      className,
      inline = false,
      check = false,
      required = false,
      invalid = false,
      disabled = false,
      placeholder = "",
      maxLength,
      autoFocus,
      clearOnDisable = false,
      tabOrder,
      id,
      inputStyle,
      onBlur,
    },
    ref
  ) => {
    const [innerValidation, setInnerValidation] = useState(true);
    const [internalValue, setInternalValue] = useState("");
    const inputRef = useRef("");
    const [internalId] = useState(
      id ?? "nick-input-" + Math.floor(Math.random() * Date.now())
    );

    const tratarTexto = (v) => (v ?? "").replaceAll(/\W/g, "").toLowerCase();

    const validateInternally = (e) => {
      if (required && e.target.value === "") {
        setInnerValidation(false);
      } else {
        setInnerValidation(true);
      }
    };

    useEffect(() => {
      if (value !== internalValue) {
        setInternalValue(tratarTexto(value));
      }
    }, [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      const inputValue = tratarTexto(e.target.value);
      setInternalValue(inputValue);
      if (onChange) {
        onChange(inputValue, e);
      }
    };

    const onBlurInternal = (e) => {
      if (onBlur) {
        const inputValue = e.target.value || "";
        onBlur(inputValue, e);
      }
      validateInternally(e);
    };

    useImperativeHandle(ref, () => ({
      isValid: () => innerValidation && !invalid,
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      setValue: (val) => setInternalValue(val),
    }));

    useEffect(() => {
      if (disabled && clearOnDisable) {
        setInternalValue("");
        if (onChange) {
          onChange("", null);
        }
      }
    }, [disabled, clearOnDisable]);

    return (
      <Col md={md}>
        <FormGroup check={check} inline={inline}>
          <Label check={check} for={internalId}>
            {label}
          </Label>
          <Col md={mdInput} className="no-gutters">
            <Input
              id={internalId}
              type="text"
              name={name}
              onChange={handleOnChange}
              onBlur={onBlurInternal}
              innerRef={inputRef}
              className={className}
              required={required}
              value={internalValue}
              invalid={!innerValidation || invalid}
              disabled={disabled}
              placeholder={placeholder}
              maxLength={maxLength}
              autoFocus={autoFocus}
              onKeyDown={handleFocus}
              tabOrder={tabOrder}
              style={inputStyle}
            />
          </Col>
          <FormFeedback valid={false}>Esse campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

export default NicknameInput;
