import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageViewer from "react-simple-image-viewer";
import { TableDelete } from "./TableDelete";

const DragDropLogo = ({ onDrop, placeholder = "Adicionar Imagem" }) => {
  const dropzoneOptions = { multiple: false, maxFiles: 1, accept: "image/*" };

  const _onDrop = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        acceptedFiles.forEach((file) => onDrop(file));
      }
    },
    [onDrop]
  );

  dropzoneOptions["onDrop"] = _onDrop;

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  return (
    <div
      {...getRootProps({
        className: "dropzone",
        style: {
          cursor: "pointer",
          justifyContent: "center",
          height: "88%",
        },
      })}
    >
      <input {...getInputProps({ multiple: false })} />
      <p style={{ marginTop: "revert" }}>{placeholder}</p>
    </div>
  );
};

export const DragDropImagem = ({
  value,
  width,
  divClassName,
  height = "auto",
  placeholder,
  onChange,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const onDrop = (file) => {
    var reader = new FileReader();

    reader.addEventListener("load", function (e) {
      onChange(e.target.result);
    });

    reader.readAsDataURL(file);
  };

  return (
    <div className={divClassName} style={{ width: width, height: height }}>
      {["", null, undefined].includes(value) ? (
        <DragDropLogo placeholder={placeholder} onDrop={onDrop} />
      ) : (
        <>
          <div
            style={{
              height: "88%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#eee",
              border: "1px solid #dee2e6",
            }}
            className="mb-2"
          >
            <img
              src={value}
              style={{ maxWidth: "100%", maxHeight: "100%", cursor: "zoom-in" }}
              alt=""
              onClick={() => setIsViewerOpen(true)}
            />
            {isViewerOpen && (
              <ImageViewer
                src={[value]}
                currentIndex={0}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={() => setIsViewerOpen(false)}
                backgroundStyle={{ backgroundColor: "#00000085" }}
              />
            )}
          </div>
          <div>
            <TableDelete onClick={() => onChange("")} />
          </div>
        </>
      )}
    </div>
  );
};
