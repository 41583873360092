import React, { FC, ReactNode } from "react";

import Wrapper from "../components/Wrapper.tsx";
import Sidebar from "../components/Sidebar.tsx";
import Main from "../components/Main.tsx";
import Navbar from "../components/Navbar.tsx";
import Content from "../components/Content.tsx";

const Dashboard = ({ username, children }) => {
  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar username={username} />
        <Main>
          <Navbar />
          <Content>{children}</Content>
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
