import { gerarNroSerieEtiqueta, padCenter } from "./utils";

import {
  Label,
  PrintDensity,
  PrintDensityName,
  Spacing,
  Text,
  FontFamily,
  FontFamilyName,
  Alignment,
  AlignmentValue,
  SizeType,
  Size,
} from "jszpl";

export const etiquetaMarco = async (
  dados,
  nroSerie = null,
  tipo = "MARCO DE PASSAGEM",
  linguagem = "PPLA",
  leftSpacing = 10
) => {
  if (!nroSerie) {
    nroSerie = await gerarNroSerieEtiqueta();
  }
  if (linguagem === "PPLA") {
    return await etiquetaMarcoPPLA(dados, nroSerie, tipo, leftSpacing);
  } else {
    return await etiquetaMarcoZPL(dados, nroSerie, tipo, leftSpacing);
  }
};

const etiquetaMarcoZPL = async (dados, nroSerie, tipo, leftSpacing = 10) => {
  let result = [];

  for (const row of dados) {
    const label = new Label();
    label.printDensity = new PrintDensity(PrintDensityName["8dpmm"]);
    label.width = 100;
    label.height = 50;
    label.padding = new Spacing(leftSpacing, 20, 20, 0);

    const txtTipoPorta = new Text();
    txtTipoPorta.text = tipo;
    txtTipoPorta.fontFamily = new FontFamily(FontFamilyName.B);
    txtTipoPorta.characterHeight = new Size(35, SizeType.Absolute);
    txtTipoPorta.characterWidth = new Size(14, SizeType.Absolute);
    txtTipoPorta.left = new Size(30, SizeType.Absolute);
    txtTipoPorta.top = new Size(0, SizeType.Absolute);
    txtTipoPorta.grid.column = 0;
    label.content.push(txtTipoPorta);

    const txtNroSerie = new Text();
    txtNroSerie.text = nroSerie;
    txtNroSerie.fontFamily = new FontFamily(FontFamilyName.B);
    txtNroSerie.characterHeight = new Size(35, SizeType.Absolute);
    txtNroSerie.characterWidth = new Size(14, SizeType.Absolute);
    txtNroSerie.horizontalAlignment = new Alignment(AlignmentValue.End);
    txtNroSerie.margin = new Spacing(0, 0, 20);
    txtNroSerie.left = new Size(0, SizeType.Absolute);
    txtNroSerie.top = new Size(0, SizeType.Absolute);
    txtNroSerie.grid.column = 0;
    label.content.push(txtNroSerie);

    const txtNomeCliente = new Text();
    txtNomeCliente.text = `${row.cliente} / ${row.cliente_final}`;
    txtNomeCliente.fontFamily = new FontFamily(FontFamilyName.B);
    txtNomeCliente.characterHeight = new Size(40, SizeType.Absolute);
    txtNomeCliente.characterWidth = new Size(14, SizeType.Absolute);
    txtNomeCliente.left = new Size(10, SizeType.Absolute);
    txtNomeCliente.top = new Size(43, SizeType.Absolute);
    txtNomeCliente.grid.column = 0;
    label.content.push(txtNomeCliente);

    const txtNomePorta = new Text();
    txtNomePorta.text = padCenter(row.nome_porta, 25);
    txtNomePorta.fontFamily = new FontFamily(FontFamilyName.B);
    txtNomePorta.characterHeight = new Size(25, SizeType.Absolute);
    txtNomePorta.characterWidth = new Size(14, SizeType.Absolute);
    txtNomePorta.left = new Size(50, SizeType.Absolute);
    txtNomePorta.top = new Size(90, SizeType.Absolute);
    txtNomePorta.grid.column = 0;
    label.content.push(txtNomePorta);

    const txtLarguraPorta = new Text();
    txtLarguraPorta.text = String(row.largura_porta);
    txtLarguraPorta.fontFamily = new FontFamily(FontFamilyName.B);
    txtLarguraPorta.characterHeight = new Size(35, SizeType.Absolute);
    txtLarguraPorta.characterWidth = new Size(14, SizeType.Absolute);
    txtLarguraPorta.left = new Size(0, SizeType.Absolute);
    txtLarguraPorta.top = new Size(129, SizeType.Absolute);
    txtLarguraPorta.grid.column = 0;
    label.content.push(txtLarguraPorta);

    const txtAlturaPorta = new Text();
    txtAlturaPorta.text = String(row.altura_porta);
    txtAlturaPorta.fontFamily = new FontFamily(FontFamilyName.B);
    txtAlturaPorta.characterHeight = new Size(35, SizeType.Absolute);
    txtAlturaPorta.characterWidth = new Size(14, SizeType.Absolute);
    txtAlturaPorta.left = new Size(275, SizeType.Absolute);
    txtAlturaPorta.top = new Size(129, SizeType.Absolute);
    txtAlturaPorta.grid.column = 0;
    label.content.push(txtAlturaPorta);

    const txtMaterial = new Text();
    txtMaterial.text = String(row.material);
    txtMaterial.fontFamily = new FontFamily(FontFamilyName.B);
    txtMaterial.characterHeight = new Size(35, SizeType.Absolute);
    txtMaterial.characterWidth = new Size(14, SizeType.Absolute);
    txtMaterial.left = new Size(0, SizeType.Absolute);
    txtMaterial.top = new Size(172, SizeType.Absolute);
    txtMaterial.grid.column = 0;
    label.content.push(txtMaterial);

    const txtCor = new Text();
    txtCor.text = row.cor;
    txtCor.fontFamily = new FontFamily(FontFamilyName.B);
    txtCor.characterHeight = new Size(35, SizeType.Absolute);
    txtCor.characterWidth = new Size(14, SizeType.Absolute);
    txtCor.left = new Size(275, SizeType.Absolute);
    txtCor.top = new Size(172, SizeType.Absolute);
    txtCor.grid.column = 0;
    label.content.push(txtCor);

    const txtMarco = new Text();
    if (row.marco) {
      txtMarco.text = `${row.marco} - ${row.espessura_vista}MM`;
    } else {
      txtMarco.text = `${row.espessura_vista}MM`;
    }
    txtMarco.fontFamily = new FontFamily(FontFamilyName.B);
    txtMarco.characterHeight = new Size(35, SizeType.Absolute);
    txtMarco.characterWidth = new Size(14, SizeType.Absolute);
    txtMarco.left = new Size(0, SizeType.Absolute);
    txtMarco.top = new Size(215, SizeType.Absolute);
    txtMarco.grid.column = 0;
    label.content.push(txtMarco);

    const txtFechadura = new Text();
    txtFechadura.text = row.fechadura;
    txtFechadura.fontFamily = new FontFamily(FontFamilyName.B);
    txtFechadura.characterHeight = new Size(35, SizeType.Absolute);
    txtFechadura.characterWidth = new Size(14, SizeType.Absolute);
    txtFechadura.left = new Size(275, SizeType.Absolute);
    txtFechadura.top = new Size(215, SizeType.Absolute);
    txtFechadura.grid.column = 0;
    label.content.push(txtFechadura);

    const txtTipoFechadura = new Text();
    txtTipoFechadura.text = row.tipo_fecha;
    txtTipoFechadura.fontFamily = new FontFamily(FontFamilyName.B);
    txtTipoFechadura.characterHeight = new Size(35, SizeType.Absolute);
    txtTipoFechadura.characterWidth = new Size(14, SizeType.Absolute);
    txtTipoFechadura.left = new Size(550, SizeType.Absolute);
    txtTipoFechadura.top = new Size(215, SizeType.Absolute);
    txtTipoFechadura.grid.column = 0;
    label.content.push(txtTipoFechadura);

    const txtReferenciaFecha = new Text();
    txtReferenciaFecha.text = String(row.referencia_fecha);
    txtReferenciaFecha.fontFamily = new FontFamily(FontFamilyName.B);
    txtReferenciaFecha.characterHeight = new Size(35, SizeType.Absolute);
    txtReferenciaFecha.characterWidth = new Size(14, SizeType.Absolute);
    txtReferenciaFecha.left = new Size(550, SizeType.Absolute);
    txtReferenciaFecha.top = new Size(258, SizeType.Absolute);
    txtReferenciaFecha.grid.column = 0;
    label.content.push(txtReferenciaFecha);

    const txtSeq = new Text();
    txtSeq.text = String(row.seq);
    txtSeq.fontFamily = new FontFamily(FontFamilyName.B);
    txtSeq.characterHeight = new Size(90, SizeType.Absolute);
    txtSeq.left = new Size(580, SizeType.Absolute);
    txtSeq.top = new Size(45, SizeType.Absolute);
    txtSeq.grid.column = 0;
    label.content.push(txtSeq);

    const txtLado = new Text();
    txtLado.text = String(row.lado);
    txtLado.fontFamily = new FontFamily(FontFamilyName.E);
    txtLado.characterHeight = new Size(90, SizeType.Absolute);
    txtLado.left = new Size(573, SizeType.Absolute);
    txtLado.top = new Size(140, SizeType.Absolute);
    txtLado.grid.column = 0;
    label.content.push(txtLado);

    result = [...result, ...label.generateZPL().split("\n")];
  }

  return result;
};

const etiquetaMarcoPPLA = async (dados, nroSerie, tipo) => {
  const resPPLA = [];
  for (const row of dados) {
    const stx = "\u0002";
    let cmd = `${stx}L\r\n`;
    if (!nroSerie) {
      nroSerie = await gerarNroSerieEtiqueta();
    }
    if (tipo != "MARCO DE PASSAGEM") {
      cmd += `121100001700060${tipo}\r\n`;
    } else {
      cmd += `121100001700250${tipo}\r\n`;
    }
    cmd += `121100001700280${nroSerie}\r\n`;
    cmd += `121100001500000${padCenter(
      `${row.cliente} / ${row.cliente_final}`,
      25
    )}\r\n`;
    cmd += `121100001250000${padCenter(row.nome_porta, 25)}\r\n`;
    cmd += `121100001000040${padCenter(row.largura_porta, 6)}\r\n`;
    cmd += `121100001000140${padCenter(row.altura_porta, 10)}\r\n`;
    cmd += `191100000750040${padCenter(row.material ?? "", 7)}\r\n`;
    cmd += `191100000750140${padCenter(row.cor ?? "", 19)}\r\n`;
    cmd += `191100000450040${padCenter(
      `${row.marco} - ${row.espessura_vista}MM`,
      8
    )}\r\n`;

    cmd += `191100701000290${padCenter(row.seq, 3)}\r\n`;

    if (["ABRIR", "CORRER"].includes(tipo)) {
      cmd += `191100000450140${padCenter(row.fechadura ?? "", 19)}\r\n`;
      cmd += `191100700650285${padCenter(row.lado ?? "", 3)}\r\n`;
      cmd += `191100000500292${padCenter(row.tipo_fecha ?? "", 7)}\r\n`;
      cmd += `191100000200292${padCenter(row.referencia_fecha ?? "", 7)}\r\n`;
    }

    cmd += "E\r\n";
    resPPLA.push(cmd);
  }

  return resPPLA;
};
