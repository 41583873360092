import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormButton from "./FormButton";
import ModalHeaderActionArea from "./ModalHeaderActionArea";
import ModalHeaderActionCheckbox from "./ModalHeaderActionCheckbox";
import { filterFormFocusableElements } from "../coreUtils";
import { apiGet } from "../api";

export const ModalBase = ({
  isOpen,
  toggle,
  size = "md",
  onOpened,
  onClosed,
  title,
  number,
  footerActions,
  autoFocus = false,
  onConfirm,
  confirmButtonText = "Confirmar",
  hideCancelButton,
  loadingConfirm,
  parameterized,
  headerCheck,
  onKeyDown,
  paramsName,
  onBeforeOpen,
  onBeforeClose,
  children,
}) => {
  const formRef = useRef();
  const modalRef = useRef();

  const onOpenedInternal = () => {
    if (!formRef.current) return;

    const focusableElements = filterFormFocusableElements(
      formRef.current?.elements
    );

    if (
      !autoFocus &&
      focusableElements.length > 0 &&
      focusableElements[0]?.focus
    ) {
      focusableElements[0].focus();
    }

    if (onOpened) onOpened();
  };

  const onKeyDownInternal = (e) => {
    const modals = Array.from(document.getElementsByClassName("modal"));

    if (modals.indexOf(modalRef.current) !== modals.length - 1) return;

    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9" && onConfirm) onConfirm();
    }

    if (onKeyDown) onKeyDown(e);
  };

  const onBeforeOpenInternal = async () => {
    if (onBeforeOpen) {
      let params = undefined;
      if (paramsName) {
        params = await apiGet(`/tela/${paramsName}/`);
      }
      onBeforeOpen(params);
    }
  };

  const onBeforeCloseInternal = () => {
    if (onBeforeClose) onBeforeClose();
  };

  useEffect(() => {
    if (isOpen) {
      onBeforeOpenInternal();
    } else {
      onBeforeCloseInternal();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      autoFocus={autoFocus}
      size={size}
      centered
      toggle={toggle}
      onOpened={onOpenedInternal}
      onClosed={onClosed}
      backdrop="static"
      onKeyDown={onKeyDownInternal}
      innerRef={modalRef}
    >
      <ModalHeader toggle={toggle}>
        {title}
        {headerCheck && (
          <ModalHeaderActionArea>
            <ModalHeaderActionCheckbox
              isActive={headerCheck.value}
              label={headerCheck.title ?? "Ativo"}
              toggleCheck={() => headerCheck.toggle()}
            />
          </ModalHeaderActionArea>
        )}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={(e) => e.preventDefault()} ref={formRef}>
          {children}
        </form>
      </ModalBody>
      {(number || footerActions || onConfirm) && (
        <ModalFooter>
          {number && (
            <span className="text-muted" style={{ marginRight: "auto" }}>
              [{number}] {parameterized && " - P*"}
            </span>
          )}
          {footerActions}
          {onConfirm && (
            <FormButton
              padded={false}
              divClassName="pr-0"
              md="auto"
              color="success"
              loading={loadingConfirm}
              onClick={onConfirm}
            >
              F9 - {confirmButtonText}
            </FormButton>
          )}
          {!hideCancelButton && (
            <FormButton
              padded={false}
              divClassName="pr-0"
              md="auto"
              color="danger"
              onClick={toggle}
            >
              Esc - Sair
            </FormButton>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};
