import React, { useRef } from "react";
import { useState } from "react";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { apiDelete, apiGet, apiPost } from "../../../../api";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  ModalBase,
  NumberInput,
} from "../../../../components";
import { VinculacaoGrupoDescontoClienteGrid } from "./VinculacaoGrupoDescontoClienteGrid";
import { toastr } from "react-redux-toastr";

const URL_BASE = "/cadastro/grupo_desconto/vinc_grupo_desconto_cliente";

export const VinculacaoGrupoDescontoClienteModal = ({ selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingIncluirUsuario, setLoadingIncluirUsuario] = useState(false);
  const [vinculacoes, setVinculacoes] = useState([]);
  const [idUsuario, setIdUsuario] = useState(null);
  const [percDescAcre, setPercDescAcre] = useState(0);

  const refComboBoxUsuario = useRef();

  const toggle = () => setIsOpen(!isOpen);

  const limparFormIncluirUsuario = () => {
    setIdUsuario(null);
    if (refComboBoxUsuario.current) {
      refComboBoxUsuario.current.clearValue();
    }
    setPercDescAcre(0);
  };

  const limparDados = () => {
    setVinculacoes([]);
    limparFormIncluirUsuario();
  };

  const fetchData = async () => {
    const ret = await apiGet(`${URL_BASE}/listar/${selected}/`);
    setVinculacoes(ret ?? []);
  };

  const incluirUsuario = async () => {
    setLoadingIncluirUsuario(true);
    try {
      if ([0, null, undefined].includes(idUsuario)) {
        toastr.warning("Atenção", "Informe o usuário e tente novamente");
        refComboBoxUsuario.current?.setFocus();
        return;
      }

      const payload = {
        id_grupo: selected,
        id_user: idUsuario,
        perc_desc_acre: percDescAcre,
      };

      const ret = await apiPost(`${URL_BASE}/incluir/`, payload);
      if (ret) {
        limparFormIncluirUsuario();
        fetchData();
      }
    } finally {
      setLoadingIncluirUsuario(false);
    }
  };

  const excluirVinculacao = async (idVinculacao) => {
    const ret = await apiDelete(`${URL_BASE}/excluir/${idVinculacao}/`);
    if (ret) {
      fetchData();
    }
  };

  return (
    <>
      <FormButton md="auto" color="primary" onClick={toggle}>
        Vinculações
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        size={"lg"}
        title={"Vinculações Grupo Desconto x Usuário"}
        number={"0003"}
        toggle={toggle}
        onBeforeOpen={fetchData}
        onBeforeClose={limparDados}
      >
        <Row className="mb-3">
          <AsyncComboBox
            label="Usuário"
            isConcatField
            concatModelName="usuario"
            defaultOptions
            value={idUsuario}
            onChange={setIdUsuario}
            md={null}
            ref={refComboBoxUsuario}
            isClearable
            isSearchable
          />
          <NumberInput
            label="% Desconto/Acréscimo"
            hint="Para conceder descontos para o usuário, informe um percentual negativo"
            value={percDescAcre}
            onChange={setPercDescAcre}
            md={3}
            allowNegativeValue
            isPercentage
          />
          <FormButton
            md="auto"
            color="info"
            onClick={incluirUsuario}
            loading={loadingIncluirUsuario}
          >
            Incluir
          </FormButton>
        </Row>
        <VinculacaoGrupoDescontoClienteGrid
          data={vinculacoes}
          excluirVinculacao={excluirVinculacao}
        />
      </ModalBase>
    </>
  );
};
