import React from "react";
import { useState } from "react";
import { ModalBase, NumberInput } from "../../../../components";
import { apiGet, apiPost } from "../../../../api";
import { Row, Col, Label, FormGroup } from "reactstrap";

export const DefinirPercentuaisUsuarioModal = ({
  selected,
  notifyEvent,
  isOpen,
  toggle,
}) => {
  const [loading, setLoading] = useState(false);
  const [grupos, setGrupos] = useState([]);

  const limparDados = () => {
    setGrupos([]);
  };

  const fetchData = async () => {
    const ret = await apiGet("/cadastro/grupo_desconto/listar/");
    setGrupos(
      ret?.map((e) => ({
        id_grupo: e.id,
        descricao: e.descricao,
        perc_desc_acre: 0,
      })) ?? []
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        id_user: selected,
        grupos_desc: grupos.map((e) => ({
          id_grupo_desc: e.id_grupo,
          perc_desc_acre: e.perc_desc_acre,
        })),
      };

      const ret = await apiPost("/cadastro/user/definir_percentuais/", payload);
      if (ret) {
        toggle();
        notifyEvent();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (idGrupo, novoPerc) => {
    console.log(grupos);
    setGrupos([
      ...grupos.map((e) => {
        if (e.id_grupo === idGrupo) {
          e.perc_desc_acre = novoPerc;
        }
        console.log(e);
        return e;
      }),
    ]);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      size={"sm"}
      title={"Definir Percentuais"}
      number={"0003"}
      toggle={toggle}
      onConfirm={grupos.length > 0 ? handleSubmit : null}
      onBeforeOpen={fetchData}
      onBeforeClose={limparDados}
      loadingConfirm={loading}
    >
      {grupos.length === 0 ? (
        <p style={{ marginBottom: "0" }}>
          Não há nenhum grupo cadastrado. Acesse o cadastro de grupos de
          desconto e cadastre um grupo
        </p>
      ) : (
        <>
          <p
            style={{
              marginBottom: "1rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Os percentuais definidos aqui irão sobrescrever TODOS os percentuais
            já definidos para o usuário
          </p>
          {grupos.map((grupo) => (
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label>&#8205;</Label>
                  <p className="pt-1">{grupo.descricao}</p>
                </FormGroup>
              </Col>
              <NumberInput
                md={4}
                label="% Desc./Acre."
                value={grupo.perc_desc_acre}
                onChange={(v) => {
                  handleOnChange(grupo.id_grupo, v);
                }}
                allowNegativeValue
                isPercentage
              />
            </Row>
          ))}
        </>
      )}
    </ModalBase>
  );
};
