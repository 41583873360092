import React from "react";
import { formatNumber } from "../../../../coreUtils";
import { DataTable, TableCheck } from "../../../../components";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_cor__descricao",
    text: "Cor",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "largura",
    text: "Largura",
    align: "center",
    headerAlign: "center",
    formatter: (c) => `${c}mm`,
  },
  {
    dataField: "comprimento",
    text: "Comprimento",
    align: "center",
    headerAlign: "center",
    formatter: (c) => `${c}mm`,
  },
  {
    dataField: "tem_friso",
    text: "Tem Friso",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
  {
    dataField: "vlr_custo",
    text: "Custo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_preco",
    text: "Preço",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const RodapeGrid = ({ data, setSelected }) => {
  return (
    <DataTable data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
