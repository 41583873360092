import React from "react";
import { DataTable, TableCheck } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
    sort: true,
  },
  {
    dataField: "descricao_material",
    text: "Material",
    align: "left",
    headerAlign: "left",
    sort: true,
  },
  {
    dataField: "id_cor__descricao",
    text: "Cor",
    align: "left",
    headerAlign: "left",
    sort: true,
  },
  {
    dataField: "descricao_tipo",
    text: "Tipo",
    align: "left",
    headerAlign: "left",
    sort: true,
  },
  {
    dataField: "largura",
    text: "Largura",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 0, true),
    sort: true,
  },
  {
    dataField: "comprimento",
    text: "Comprimento",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 0, true),
    sort: true,
  },
  {
    dataField: "vlr_custo",
    text: "Custo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_preco",
    text: "Preço",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "ativo",
    text: "Ativo",
    align: "center",
    headerAlign: "center",
    formatter: (cell) => <TableCheck value={cell} />,
  },
];

export const VistaGrid = ({ data, setSelected }) => {
  return (
    <DataTable data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
