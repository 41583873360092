import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroFechoUnhaModal } from "./components/CadastroFechoUnhaModal";
import { FechoUnhaGrid } from "./components/FechoUnhaGrid";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../coreUtils";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../components";
import { apiGet } from "../../../api";

export const FechoUnha = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p) => {
    const ret = await apiGet("/cadastro/fecho_unha/listar", { pista: p });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer title="Cadastro de Fecho Unha" number="0008">
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroFechoUnhaModal
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <FechoUnhaGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
