import React, { useRef } from "react";
import { useState } from "react";
import { Row } from "reactstrap";
import { ModalBase, PasswordInput } from "../../../../components";
import { toastr } from "react-redux-toastr";
import { apiPut } from "../../../../api";

export const AlterarSenhaUsuarioModal = ({
  selected,
  notifyEvent,
  isOpen,
  toggle,
}) => {
  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState("");

  const refSenha = useRef();

  const limparDados = () => {
    setSenha("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!refSenha.current?.validarSenha()) {
        toastr.warning(
          "Atenção",
          "Revise as indicações da senha e tente novamente"
        );
        return;
      }

      const payload = {
        id_user: selected,
        senha: senha,
      };

      const ret = await apiPut("/cadastro/user/alterar_senha/", payload);
      if (ret) {
        toggle();
        notifyEvent();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      size={"sm"}
      title={"Alterar Senha"}
      number={"0003"}
      toggle={toggle}
      onConfirm={handleSubmit}
      onBeforeClose={limparDados}
      loadingConfirm={loading}
    >
      <Row>
        <PasswordInput
          md={12}
          label="Senha"
          value={senha}
          onChange={setSenha}
          maxLength={20}
          ref={refSenha}
        />
      </Row>
    </ModalBase>
  );
};
