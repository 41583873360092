import React from "react";
import { useState } from "react";
import {
  AsyncComboBox,
  IntegerInput,
  ModalCadastro,
  NumberInput,
  RadioGroup,
} from "../../../../components";
import { MODAL_ACTIONS, tiposMaterial } from "../../../../coreUtils";
import { Row } from "reactstrap";
import FormLabel from "../../../../components/FormLabel";

export const tiposVista = [
  { label: "Fixa", value: "FIXA" },
  { label: "Regulável", value: "REGULAVEL" },
  { label: "Correr", value: "CORRER" },
  { label: "Super Regulável", value: "SUPER_REGULA" },
];

export const CadastroVistaModal = ({ selected, notifyEvent }) => {
  const [ativo, setAtivo] = useState(true);
  const [tipo, setTipo] = useState(tiposVista[0].value);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [comprimento, setComprimento] = useState(0);
  const [largura, setLargura] = useState(0);
  const [idCor, setIdCor] = useState(null);
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [peso, setPeso] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setTipo(tiposVista[0].value);
    setTipoMaterial(tiposMaterial[0].value);
    setComprimento(0);
    setLargura(0);
    setVlrCusto(0);
    setVlrPreco(0);
    setPeso(0);
    setAtivo(true);
    setIdIntegracao(0);
    setIdGrupoDesc(null);
    setIdCor(null);
  };

  const fetchData = (data) => {
    setTipo(data.tipo);
    setTipoMaterial(data.tipo_material);
    setComprimento(parseFloat(data.comprimento));
    setLargura(parseFloat(data.largura));
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdCor(data.id_cor);
    setPeso(parseFloat(data.peso));
    setAtivo(data.ativo);
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      tipo: tipo,
      tipo_material: tipoMaterial,
      comprimento: comprimento,
      largura: largura,
      id_cor: idCor,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      peso: peso,
      ativo: ativo,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Vista"
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase="/cadastro/vista"
      number="0010_1"
      deleteModalNumber="0010_2"
      selected={selected}
      notifyEvent={notifyEvent}
      buttonsPadded={false}
    >
      <Row>
        <RadioGroup
          label="Material"
          options={tiposMaterial}
          value={tipoMaterial}
          onChange={setTipoMaterial}
        />
        <IntegerInput
          label="Largura"
          value={largura}
          onChange={setLargura}
          placeholder="Em Milímetros"
        />
        <IntegerInput
          md={3}
          label="Comprimento"
          value={comprimento}
          placeholder="Em Milímetros"
          onChange={setComprimento}
        />
        <FormLabel md={1}>{`${comprimento / 1000}m`}</FormLabel>
      </Row>
      <Row>
        <RadioGroup
          label="Tipo"
          options={tiposVista}
          value={tipo}
          onChange={setTipo}
        />
        <NumberInput label="Peso (Kg)" value={peso} onChange={setPeso} />
        <AsyncComboBox
          label="Cor"
          isConcatField
          concatModelName="cor_acabamento"
          isClearable
          isSearchable
          defaultOptions
          value={idCor}
          onChange={setIdCor}
          md={4}
        />
      </Row>
      <Row>
        <NumberInput label="Custo" value={vlrCusto} onChange={setVlrCusto} />
        <NumberInput label="Preco" value={vlrPreco} onChange={setVlrPreco} />
        <IntegerInput
          label="Cód Integra"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
        />
      </Row>
    </ModalCadastro>
  );
};
