import ImpressaoEtiquetaService from "../../../../services/modules/pedidos/ImpressaoEtiquetaService";

export const padCenter = (str, maxLen) => {
  str = String(str);
  return str.padStart((str.length + maxLen) / 2).padEnd(maxLen);
};

export const gerarNroSerieEtiqueta = async () => {
  const ret = await ImpressaoEtiquetaService.buscarProximoNroSerie();
  if (!ret) {
    throw Error("Erro ao buscar número de série das etiquetas");
  }
  return String(ret["prox_nro_serie"]).padStart(9, "0");
};
