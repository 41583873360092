import React from "react";
import { ColorField, DataTable } from "../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../coreUtils";

const statusPedido = [
  {
    value: "DIG",
    color: "#8102a1",
    hint: "Em Digitação",
  },
  {
    value: "AGC",
    color: "#4287f5",
    hint: "Aguardando Confirmação",
  },
  {
    value: "AEE",
    color: "#dbd818",
    hint: "Aguardando Envio para Ecoplac",
  },
  {
    value: "CON",
    color: "#1cb9fc",
    hint: "Pedido Confirmado",
  },
];

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nro_pedido",
    text: "Nº do Pedido",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_user__nome",
    text: "Emitente",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "descricao_ped",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "vlr_frete",
    text: "Frete",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_total_sem_markup",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "status",
    text: "Status",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <ColorField scheme={statusPedido} value={c} />,
  },
];

export const GerenciamentoPedidosGrid = ({ data, setSelected }) => {
  return (
    <DataTable data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
