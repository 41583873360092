import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { handleFocus } from "../coreUtils";

const TableTextInput = forwardRef(
  (
    {
      type = "text",
      name,
      onChange,
      value,
      className,
      maxLength,
      tabOrder,
      id,
      inputStyle,
      onBlur,
      autoFocus,
      size,
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState("");
    const [callOnBlur, setCallOnBlur] = useState(false);
    const inputRef = useRef("");
    const [internalId] = useState(
      id ?? "text-input-" + Math.floor(Math.random() * Date.now())
    );

    useEffect(() => {
      if (value?.toUpperCase() !== internalValue?.toUpperCase()) {
        setInternalValue(value?.toUpperCase());
      }
    }, [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      const inputValue = e.target.value || "";
      setInternalValue(inputValue);
      if (onChange) {
        setCallOnBlur(true);
        onChange(e, inputValue?.toUpperCase());
      }
    };

    const onKeyDown = (e) => {
      if (type !== "textarea" || (e.key !== "Enter" && e.key !== "ArrowUp")) {
        handleFocus(e);
      }
    };

    const onBlurInternal = (e) => {
      if (onBlur) {
        const inputValue = e.target.value || "";
        if (callOnBlur) onBlur(e, inputValue?.toUpperCase());
      }
      setCallOnBlur(false);
    };

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: internalValue?.toUpperCase()?.trim(),
      setValue: (val) => setInternalValue(val),
    }));

    return (
      <input
        id={internalId}
        autoFocus={autoFocus}
        type={type}
        name={name}
        onChange={handleOnChange}
        onBlur={onBlurInternal}
        innerRef={inputRef}
        className={className}
        value={internalValue}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        tabOrder={tabOrder}
        style={{ ...inputStyle }}
        size={size}
      />
    );
  }
);

export default TableTextInput;
