import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import settings from "./settings";

const kClientId = "xc3BYHQIbFFQL00nhwuSBL10ULggkYOK6vfivM1X";
const kClientSecret =
  "MUi14fA8i8pYiDhk3oymVgzL4MRGkWBCT5c0TFCzqaLy0xnsb5MvY5K8KlGCdfmIfC" +
  "EEmPjJc3Gmz8mqXAKF71v1AtzyeSCGD2R9Jco2JVMFe4hP1IrndHTuzrz5ZPBG";

let api = axios.create({
  baseURL: Cookies.get("api-address"),
  headers: {
    Authorization: `Bearer ${Cookies.get("access-token")}`,
  },
});

export const updateAPI = (apiAddress, dbName, accessToken) => {
  api = axios.create({
    baseURL: apiAddress,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Atualiza token caso necessário
const refreshToken = async (error) => {
  try {
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: Cookies.get("refresh-token"),
    });
    await axios
      .post(`${settings.AUTENTICADOR}/o/token/`, data, {
        timeout: 5000,
        auth: { username: kClientId, password: kClientSecret },
      })
      .then(async (res) => {
        Cookies.set("access-token", res.data.access_token);
        Cookies.set("refresh-token", res.data.refresh_token);
        return res;
      })
      .catch((_) => {
        return error;
      });
  } catch (err) {
    return err;
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const access_token = Cookies.get("access-token");
    if ([403, 401].includes(error.response.status) && access_token) {
      const response = await refreshToken(error);
      return response;
    }
    return Promise.reject(error);
  }
);

export { api, kClientId, kClientSecret };

export const urlParams = (params) =>
  ![null, undefined, {}].includes(params)
    ? "?" +
      new URLSearchParams(
        Object.keys(params)
          .filter((key) => ![null, undefined, ""].includes(params[key]))
          .reduce((obj, key) => {
            if (params[key] instanceof Date) {
              params[key] = params[key].toISOString().split("T")[0];
            }
            return Object.assign(obj, {
              [key]: params[key],
            });
          }, {})
      ).toString()
    : "";

export const revokeToken = async () => {
  try {
    const data = new URLSearchParams({
      token: Cookies.get("access-token"),
      client_id: kClientId,
      client_secret: kClientSecret,
    });
    await axios
      .post(`${settings.AUTENTICADOR}/o/revoke-token/`, data, {
        timeout: 5000,
      })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    return err;
  }
};

const processaRetorno = (ret, options) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (options?.successMesage) {
      toastr.success("Sucesso", ret.data.msg);
    }

    return options?.returnDataSuccess ? ret.data.res : true;
  } else {
    toastr.error("Erro", ret.data.msg);
    return false;
  }
};

const processaErro = (err) => {
  toastr.error("Erro", err.message);
  return false;
};

const apiGet = async (url, params, options) =>
  await api
    .get(url + urlParams(params))
    .then((ret) =>
      processaRetorno(ret, { returnDataSuccess: true, ...options })
    )
    .catch((err) => processaErro(err));

const apiPost = async (url, payload, options) =>
  await api
    .post(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

const apiPut = async (url, payload, options) =>
  await api
    .put(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

const apiDelete = async (url) =>
  await api
    .delete(url)
    .then((ret) => processaRetorno(ret, { successMesage: true }))
    .catch((err) => processaErro(err));

export { apiGet, apiPost, apiPut, apiDelete };
