import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AsyncSelect from "react-select/async";
import { Col, FormGroup, Label } from "reactstrap";
import { handleFocus } from "../coreUtils";
import ComboBoxService from "../services/components/ComboBoxService";
import { ConcatShortcut } from "./ConcatShortcut";

const AsyncComboBox = forwardRef(
  (
    {
      loadOptions,
      onChange,
      isClearable = false,
      isMulti = false,
      isSearchable = true,
      hideShortcut = false,
      md = 4,
      label,
      placeholder = "Selecione...",
      name,
      className,
      defaultOptions,
      isConcatField,
      concatModelName,
      value,
      options,
      disabled,
      clearOnDisable,
      autoFocus,
      tabOrder,
      onBlur,
      aditionalFilter,
      defaultValue,
      id,
    },
    ref
  ) => {
    const [selectedOption, setSelectOption] = useState({});
    const selectRef = useRef();
    const [loadedOptions, setLoadedOptions] = useState(loadOptions);
    const [internalId] = useState(id ?? Math.floor(Math.random() * Date.now()));

    const fetchOptions = async (inputValue) => {
      let _result = [];
      if (isConcatField && typeof concatModelName == "string") {
        const filters = {};

        if (aditionalFilter) {
          Object.keys(aditionalFilter).forEach((key) => {
            filters[key] = aditionalFilter[key];
          });
        }

        if (inputValue !== "" && ![null, undefined].includes(inputValue)) {
          if (!isNaN(inputValue)) {
            if (concatModelName === "cliente") {
              filters["pk"] = inputValue;
            } else {
              filters["id"] = inputValue;
            }
          } else {
            let nomeCampo = "nome";
            if (["cantoneira", "cor_acabamento"].includes(concatModelName)) {
              nomeCampo = "descricao";
            }
            if (concatModelName === "cliente") {
              filters[nomeCampo] = inputValue;
            } else {
              filters[`${nomeCampo}__icontains`] = inputValue;
            }
          }
        }
        const ret = await ComboBoxService.fetchOptions(
          concatModelName,
          filters
        );
        setLoadedOptions(true);
        _result = ret;
      } else {
        _result = options;
      }
      return _result;
    };

    const clearValue = () => {
      selectRef.current.clearValue();
    };

    const setValue = (value) => {
      selectRef.current.setValue(value);
    };

    const setValueByID = useCallback(async (id) => {
      let i = 0;
      let checked = false;
      while (!checked && i < 10) {
        const options = getOptions();
        if (options.length > 0) {
          setValue(options.find((v) => v.value === id));
          checked = true;
        }
        i++;
        await (async () => setTimeout(() => {}, 75))();
      }
    }, []);

    const getOptions = () => {
      if (!selectRef?.current) return [];
      return selectRef.current.props.options;
    };

    const setFocus = () => {
      selectRef.current.focus();
    };

    useImperativeHandle(ref, () => ({
      clearValue: () => clearValue(),
      setValue: (v) => setValue(v),
      getOptions: () => getOptions(),
      setValueByID: (id) => setValueByID(id),
      getValue: () => selectedOption,
      setFocus: () => setFocus(),
    }));

    const __onChange = (selected, target) => {
      setSelectOption(selected);
      if (onChange) {
        onChange(
          isMulti ? selected : selected?.value ?? null,
          selected,
          target
        );
      }
    };

    // Quando o value é zero, não é atribuida a opção no Select, pois zero é falsy
    // Esta função abre uma exceção quando o concatModelName for colaborador
    const checkValueUpdate = (v) => {
      if (concatModelName === "colaborador") {
        return v === 0 ? true : v;
      } else {
        return v;
      }
    };

    useEffect(() => {
      if (
        checkValueUpdate(value) &&
        (loadedOptions || typeof defaultOptions !== Array)
      ) {
        (async () => setTimeout(() => setValueByID(value), 1000))();
      }
    }, [setValueByID, value, loadedOptions]);

    useEffect(() => {
      if (disabled && clearOnDisable) {
        clearValue();
      }
    }, [disabled, clearOnDisable]);

    const setSelectedByShortcut = async (s) => {
      const ids = getOptions().map((e) => e.value);

      if (!ids.includes(s)) {
        const newValues = await fetchOptions(s);
        if (newValues?.length > 0) {
          selectRef.current.props.options.push(newValues[0]);
        }
      }

      setValueByID(s);
    };

    const onKeyDownInternal = (e) => {
      if (e.key === "Enter" && isMulti) return;
      handleFocus(e);
    };

    useEffect(() => {
      fetchOptions();
    }, [aditionalFilter]);

    return (
      <Col md={md} className={className}>
        <FormGroup onKeyDown={onKeyDownInternal}>
          <Label for={`react-select-${internalId}-input`}>{label}</Label>
          <div style={{ display: "flex", width: "100%" }}>
            <AsyncSelect
              placeholder={placeholder}
              className="react-select-container"
              classNamePrefix="react-select"
              key={JSON.stringify(aditionalFilter)}
              loadOptions={isConcatField ? fetchOptions : loadOptions}
              name={name}
              isSearchable={isSearchable}
              isClearable={isClearable}
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              onChange={__onChange}
              ref={selectRef}
              cacheOptions
              isDisabled={disabled}
              autoFocus={autoFocus}
              noOptionsMessage={() =>
                isConcatField ? "Digite Algo" : "Sem Dados"
              }
              loadingMessage={() => "Carregando..."}
              isLoading={
                typeof defaultOptions === Boolean &&
                defaultOptions &&
                !loadedOptions
              }
              tabOrder={tabOrder}
              onBlur={onBlur}
              defaultValue={defaultValue}
              instanceId={internalId}
            />
            {!hideShortcut && isConcatField && !disabled && (
              <ConcatShortcut
                concatModelName={concatModelName}
                setSelected={setSelectedByShortcut}
              />
            )}
          </div>
        </FormGroup>
      </Col>
    );
  }
);

export default AsyncComboBox;
