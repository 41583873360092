import Cookies from "js-cookie";
import { useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { revokeToken } from "../api";

const initialState = {
  accessToken: Cookies.get("access-token"),
  refreshToken: Cookies.get("refresh-token"),
  dbName: Cookies.get("database-name"),
  apiAddress: Cookies.get("api-address"),
};
let configureSessionGlobal = () => {
  throw new Error("Você deve chamar useSession antes de logar.");
};

const useSessionImpl = () => {
  const [session, setSession] = useState(initialState);
  configureSessionGlobal = setSession;
  return session;
};

export const useSession = singletonHook(initialState, useSessionImpl);

export const login = (accessToken, refreshToken, dbName, apiAddress) => {
  Cookies.set("access-token", accessToken);
  Cookies.set("refresh-token", refreshToken);
  Cookies.set("database-name", dbName);
  Cookies.set("api-address", apiAddress);
  configureSessionGlobal({ accessToken, refreshToken, dbName, apiAddress });
};

export const logoff = async () => {
  await revokeToken();
  Cookies.remove("access-token");
  Cookies.remove("refresh-token");
  Cookies.remove("database-name");
  Cookies.remove("api-address");
  configureSessionGlobal({
    accessToken: "",
    refreshToken: "",
    dbName: "",
    apiAddress: "",
  });
};
