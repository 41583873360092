import React from "react";

import { BsCheck, BsInfo, BsX } from "react-icons/bs";
import { Col, Row } from "reactstrap";

export const ItemRegraSenha = ({ label, regraOk, info }) => {
  return (
    <Row style={{ marginTop: "5px" }}>
      <Col>
        {info ? (
          <BsInfo size={15} />
        ) : regraOk ? (
          <BsCheck size={15} color={"green"} />
        ) : (
          <BsX size={15} color={"red"} />
        )}{" "}
        <span style={{ color: info ? "inherit" : regraOk ? "green" : "red" }}>
          {label}
        </span>
      </Col>
    </Row>
  );
};
