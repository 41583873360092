import React from "react";
import { useState } from "react";
import {
  ModalCadastro,
  NumberInput,
  IntegerInput,
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Row } from "reactstrap";

const larguraOptions = [
  { label: "70mm", value: 70 },
  { label: "100mm", value: 100 },
  { label: "150mm", value: 150 },
];

export const CadastroRodapeModal = ({ selected, notifyEvent }) => {
  const [idCor, setIdCor] = useState(null);
  const [largura, setLargura] = useState(null);
  const [comprimento, setComprimento] = useState(null);
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [temFriso, setTemFriso] = useState(false);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setIdCor(null);
    setVlrCusto(0);
    setVlrPreco(0);
    setLargura(null);
    setComprimento(null);
    setTemFriso(false);
    setIdIntegracao(0);
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setIdCor(data.id_cor);
    setLargura(data.largura);
    setComprimento(data.comprimento);
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setTemFriso(data.tem_friso);
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      id_cor: idCor ?? 0,
      largura: largura,
      comprimento: comprimento,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      tem_friso: temFriso,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Rodapé"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/rodape"
      number="0003_1"
      deleteModalNumber="0003_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          md={3}
          label="Cor"
          isConcatField
          defaultOptions
          concatModelName="cor_acabamento"
          value={idCor}
          onChange={setIdCor}
        />
        <ComboBox
          md={3}
          label="Largura"
          options={larguraOptions}
          value={largura}
          onChange={setLargura}
          isSearchable={false}
          isClearable={false}
        />
        <IntegerInput
          label="Comprimento"
          md={3}
          value={comprimento}
          onChange={setComprimento}
        />
        <FormCheckbox
          label="Tem Friso"
          checked={temFriso}
          onChange={() => setTemFriso(!temFriso)}
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Custo"
          value={vlrCusto}
          onChange={setVlrCusto}
        />
        <NumberInput
          md={4}
          label="Preco"
          value={vlrPreco}
          onChange={setVlrPreco}
        />
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
        />
      </Row>
    </ModalCadastro>
  );
};
