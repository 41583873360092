import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Eye, EyeOff } from "react-feather";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Col,
} from "reactstrap";
import { ItemRegraSenha } from "./ItemRegraSenha";

export const PasswordInput = forwardRef(
  ({ md, label = "Senha", value, onChange, placeholder, maxLength }, ref) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const validarRegraLetras = () =>
      (value?.match(/[a-z]/gi) ?? []).length >= 3;

    const validarRegraNumeros = () =>
      (value?.match(/[0-9]/gi) ?? []).length >= 3;

    const validarSenha = () => validarRegraLetras() && validarRegraNumeros;

    useImperativeHandle(ref, () => ({ validarSenha: () => validarSenha() }));

    return (
      <Col md={md}>
        <FormGroup>
          <Label>{label}</Label>
          <InputGroup>
            <Input
              type={passwordVisible ? "text" : "password"}
              name="password"
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              maxLength={maxLength}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText
                style={{ cursor: "pointer", backgroundColor: "white" }}
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <Eye size={15} /> : <EyeOff size={15} />}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <PasswordStrengthBar
            style={{ marginTop: "10px" }}
            password={value}
            scoreWordStyle={{ display: "none" }}
            // onChangeScore={(score) => setPasswordScore(score)}
          />
          <ItemRegraSenha
            label="A senha deve conter pelo menos 3 letras"
            regraOk={validarRegraLetras()}
          />
          <ItemRegraSenha
            label="A senha deve conter pelo menos 3 números"
            regraOk={validarRegraNumeros()}
          />
        </FormGroup>
      </Col>
    );
  }
);
