import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroGrupoDescontoModal } from "./components/CadastroGrupoDescontoModal";
import { GrupoDescontoGrid } from "./components/GrupoDescontoGrid";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../coreUtils";
import { apiGet } from "../../../api";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../components";
import { VinculacaoGrupoDescontoClienteModal } from "./components/VinculacaoGrupoDescontoClienteModal";

export const GrupoDesconto = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p) => {
    const ret = await apiGet("/cadastro/grupo_desconto/listar", {
      pista: p,
    });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer title="Cadastro de Grupos de Desconto" number="0003">
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroGrupoDescontoModal
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <VinculacaoGrupoDescontoClienteModal selected={selected} />
        </Row>
      </Card>
      <Card body>
        <GrupoDescontoGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
