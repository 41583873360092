import { padCenter } from "./utils";
import {
  Label,
  PrintDensity,
  PrintDensityName,
  Spacing,
  Text,
  FontFamily,
  FontFamilyName,
  Alignment,
  AlignmentValue,
  SizeType,
  Size,
} from "jszpl";

export const etiquetaVista = (
  row,
  tipo,
  tipoVista,
  nroSerie,
  linguagem = "ZPL"
) => {
  // A impressão das portas foi desenvolvido em PPLA
  if (linguagem === "PPLA") {
    return [etiquetaVistaPPLA(row, tipo, tipoVista, nroSerie)];
  } else {
    return etiquetaVistaZPL(row, tipo, tipoVista, nroSerie);
  }
};

const etiquetaVistaZPL = (row, tipo, tipoVista, nroSerie) => {
  const label = new Label();
  label.printDensity = new PrintDensity(PrintDensityName["8dpmm"]);
  label.width = 100;
  label.height = 50;
  label.padding = new Spacing(45, 20);

  const txtTipoPorta = new Text();
  txtTipoPorta.text = tipo;
  txtTipoPorta.fontFamily = new FontFamily(FontFamilyName.B);
  txtTipoPorta.characterHeight = new Size(35, SizeType.Absolute);
  txtTipoPorta.characterWidth = new Size(14, SizeType.Absolute);
  txtTipoPorta.left = new Size(120, SizeType.Absolute);
  txtTipoPorta.top = new Size(0, SizeType.Absolute);
  txtTipoPorta.grid.column = 0;
  label.content.push(txtTipoPorta);

  const txtNroSerie = new Text();
  txtNroSerie.text = nroSerie;
  txtNroSerie.fontFamily = new FontFamily(FontFamilyName.B);
  txtNroSerie.characterHeight = new Size(35, SizeType.Absolute);
  txtNroSerie.characterWidth = new Size(14, SizeType.Absolute);
  txtNroSerie.horizontalAlignment = new Alignment(AlignmentValue.End);
  txtNroSerie.margin = new Spacing(0, 0, 20);
  txtNroSerie.left = new Size(0, SizeType.Absolute);
  txtNroSerie.top = new Size(0, SizeType.Absolute);
  txtNroSerie.grid.column = 0;
  label.content.push(txtNroSerie);

  const txtNomeCliente = new Text();
  txtNomeCliente.text = `${row.cliente} / ${row.cliente_final}`;
  txtNomeCliente.fontFamily = new FontFamily(FontFamilyName.B);
  txtNomeCliente.characterHeight = new Size(40, SizeType.Absolute);
  txtNomeCliente.characterWidth = new Size(14, SizeType.Absolute);
  txtNomeCliente.left = new Size(10, SizeType.Absolute);
  txtNomeCliente.top = new Size(43, SizeType.Absolute);
  txtNomeCliente.grid.column = 0;
  label.content.push(txtNomeCliente);

  const txtNomePorta = new Text();
  txtNomePorta.text = padCenter(row.nome_porta, 25);
  txtNomePorta.fontFamily = new FontFamily(FontFamilyName.B);
  txtNomePorta.characterHeight = new Size(25, SizeType.Absolute);
  txtNomePorta.characterWidth = new Size(14, SizeType.Absolute);
  txtNomePorta.left = new Size(50, SizeType.Absolute);
  txtNomePorta.top = new Size(90, SizeType.Absolute);
  txtNomePorta.grid.column = 0;
  label.content.push(txtNomePorta);

  const txtLarguraPorta = new Text();
  txtLarguraPorta.text = String(row.largura_porta);
  txtLarguraPorta.fontFamily = new FontFamily(FontFamilyName.B);
  txtLarguraPorta.characterHeight = new Size(35, SizeType.Absolute);
  txtLarguraPorta.characterWidth = new Size(14, SizeType.Absolute);
  txtLarguraPorta.left = new Size(0, SizeType.Absolute);
  txtLarguraPorta.top = new Size(129, SizeType.Absolute);
  txtLarguraPorta.grid.column = 0;
  label.content.push(txtLarguraPorta);

  const txtAlturaPorta = new Text();
  txtAlturaPorta.text = String(row.altura_porta);
  txtAlturaPorta.fontFamily = new FontFamily(FontFamilyName.B);
  txtAlturaPorta.characterHeight = new Size(35, SizeType.Absolute);
  txtAlturaPorta.characterWidth = new Size(14, SizeType.Absolute);
  txtAlturaPorta.left = new Size(275, SizeType.Absolute);
  txtAlturaPorta.top = new Size(129, SizeType.Absolute);
  txtAlturaPorta.grid.column = 0;
  label.content.push(txtAlturaPorta);

  const txtMaterial = new Text();
  txtMaterial.text = String(row.material ?? "");
  txtMaterial.fontFamily = new FontFamily(FontFamilyName.B);
  txtMaterial.characterHeight = new Size(35, SizeType.Absolute);
  txtMaterial.characterWidth = new Size(14, SizeType.Absolute);
  txtMaterial.left = new Size(0, SizeType.Absolute);
  txtMaterial.top = new Size(172, SizeType.Absolute);
  txtMaterial.grid.column = 0;
  label.content.push(txtMaterial);

  const txtCor = new Text();
  txtCor.text = row.cor ?? "";
  txtCor.fontFamily = new FontFamily(FontFamilyName.B);
  txtCor.characterHeight = new Size(35, SizeType.Absolute);
  txtCor.characterWidth = new Size(14, SizeType.Absolute);
  txtCor.left = new Size(275, SizeType.Absolute);
  txtCor.top = new Size(172, SizeType.Absolute);
  txtCor.grid.column = 0;
  label.content.push(txtCor);

  const txtSeq = new Text();
  txtSeq.text = String(row.seq);
  txtSeq.fontFamily = new FontFamily(FontFamilyName.B);
  txtSeq.characterHeight = new Size(90, SizeType.Absolute);
  txtSeq.left = new Size(580, SizeType.Absolute);
  txtSeq.top = new Size(45, SizeType.Absolute);
  txtSeq.grid.column = 0;
  label.content.push(txtSeq);

  const txtVista = new Text();
  txtVista.text = `VISTA ${tipoVista} ${row.largura_vista}MM ${row.espessura_vista}MM`;
  txtVista.fontFamily = new FontFamily(FontFamilyName.B);
  txtVista.characterHeight = new Size(35, SizeType.Absolute);
  txtVista.characterWidth = new Size(14, SizeType.Absolute);
  txtVista.verticalAlignment = new Alignment(AlignmentValue.End);
  txtVista.horizontalAlignment = new Alignment(AlignmentValue.Center);
  txtVista.margin = new Spacing(0, 0, 0, 25);
  txtVista.grid.column = 0;
  label.content.push(txtVista);

  return label.generateZPL().split("\n");
};

const etiquetaVistaPPLA = (row, tipo, tipoVista, nroSerie) => {
  const stx = "\u0002";
  let cmd = `${stx}L\r\n`;
  cmd += `121100001700130${tipo}\r\n`;
  cmd += `121100001700280${nroSerie}\r\n`;
  cmd += `121100001500000${padCenter(
    `${row.cliente} / ${row.cliente_final}`,
    25
  )}\r\n`;
  cmd += `121100001250000${padCenter(row.nome_porta, 25)}\r\n`;
  cmd += `121100001000040${padCenter(row.largura_porta, 6)}\r\n`;
  cmd += `121100001000140${padCenter(row.altura_porta, 10)}\r\n`;
  cmd += `191100000750040${padCenter(row.material, 7)}\r\n`;
  cmd += `191100000750140${padCenter(row.cor, 19)}\r\n`;

  cmd += `191100701000290${padCenter(row.seq, 3)}\r\n`;
  if (tipo === "CORRER") {
    cmd += `191100000050010${padCenter(tipoVista, 50)}\r\n`;
  } else {
    cmd += `191100000050010${padCenter(
      `VISTA ${tipoVista} ${row.largura_vista}MM ${row.espessura_vista}MM`,
      50
    )}\r\n`;
  }

  cmd += "E\r\n";
  return cmd;
};
