import React, { useRef } from "react";
import { useState } from "react";
import {
  ModalCadastro,
  NumberInput,
  IntegerInput,
  AsyncComboBox,
  Divider,
  TextInput,
  FormButton,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { ItensCaixaFerragemGrid } from "./ItensCaixaFerragemGrid";
import { toastr } from "react-redux-toastr";

export const CadastroCaixaTrilhoModal = ({ selected, notifyEvent }) => {
  const [cor, setCor] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);
  const [itensCaixaFerragem, setItensCaixaFerragem] = useState([]);

  // Itens caixa de ferragem
  const [nomeItemCxaFrg, setNomeItemCxaFrg] = useState(null);
  const [quantidadeItemCxaFrg, setQuantidadeItemCxaFrg] = useState(null);
  const [vlrUnitItemCxaFrg, setVlrUnitItemCxaFrg] = useState(null);
  const [idIntegracaoItemCxaFrg, setIdIntegracaoItemCxaFrg] = useState(null);

  const nomeItemCxaFrgRef = useRef();

  const limparDadosIncluirItemCaixaFerragem = () => {
    setNomeItemCxaFrg("");
    setQuantidadeItemCxaFrg(null);
    setVlrUnitItemCxaFrg(null);
    setIdIntegracaoItemCxaFrg(null);
  };

  const limparDados = () => {
    setCor(null);
    setDescricao("");
    setVlrCusto(0);
    setVlrPreco(0);
    setIdIntegracao(0);
    setIdGrupoDesc(null);
    setItensCaixaFerragem([]);
    limparDadosIncluirItemCaixaFerragem();
  };

  const fetchData = (data) => {
    setCor(data.id_cor);
    setDescricao(data.descricao);
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
    setItensCaixaFerragem(
      data.itens_caixa_ferragem.map((e) => ({
        uuid: uuidv4(),
        nome: e.nome,
        quantidade: parseFloat(e.quantidade),
        vlr_unit: parseFloat(e.vlr_unit),
        id_integracao: e.id_integracao,
      }))
    );
  };

  const submitPayload = (action) => {
    const payload = {
      descricao: descricao,
      id_cor: cor,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
      itens_caixa_ferragem: itensCaixaFerragem.map((e) => ({
        nome: e.nome,
        quantidade: e.quantidade,
        vlr_unit: e.vlr_unit,
        id_integracao: e.id_integracao,
      })),
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_caixa_trilho: selected, ...payload };
  };

  const incluirItemCaixaFerragem = () => {
    if (["", null, undefined].includes(nomeItemCxaFrg?.trim())) {
      toastr.warning("Atenção", "Informe o nome do item da caixa de ferragem");
      return;
    }

    if ([0, null, undefined].includes(quantidadeItemCxaFrg)) {
      toastr.warning(
        "Atenção",
        "Informe a quantidade do item da caixa de ferragem"
      );
      return;
    }

    setItensCaixaFerragem([
      ...itensCaixaFerragem,
      {
        uuid: uuidv4(),
        nome: nomeItemCxaFrg,
        quantidade: quantidadeItemCxaFrg,
        vlr_unit: vlrUnitItemCxaFrg ?? 0,
        id_integracao: idIntegracaoItemCxaFrg ?? 0,
      },
    ]);
    limparDadosIncluirItemCaixaFerragem();
    nomeItemCxaFrgRef.current?.focus();
  };

  const removerItemCaixaFerragem = (uuidItem) => {
    setItensCaixaFerragem([
      ...itensCaixaFerragem.filter((e) => e.uuid != uuidItem),
    ]);
  };

  return (
    <ModalCadastro
      title="Caixa de Trilho"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/caixa_trilho"
      number="0003_1"
      deleteModalNumber="0003_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Descrição"
          value={descricao}
          onChange={setDescricao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Cor de Acabamento"
          isConcatField
          concatModelName="cor_acabamento"
          defaultOptions
          value={cor}
          onChange={setCor}
          md={6}
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Custo"
          value={vlrCusto}
          onChange={setVlrCusto}
        />
        <NumberInput
          md={4}
          label="Preco"
          value={vlrPreco}
          onChange={setVlrPreco}
        />
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
        />
      </Row>
      <Divider>Itens da Caixa de Ferragem</Divider>
      <Row>
        <TextInput
          label="Nome"
          value={nomeItemCxaFrg}
          onChange={setNomeItemCxaFrg}
          ref={nomeItemCxaFrgRef}
        />
      </Row>
      <Row className="mb-3">
        <NumberInput
          md={3}
          label="Quantidade"
          value={quantidadeItemCxaFrg}
          onChange={setQuantidadeItemCxaFrg}
        />
        <NumberInput
          md={3}
          label="Preco Unitário"
          value={vlrUnitItemCxaFrg}
          onChange={setVlrUnitItemCxaFrg}
        />
        <IntegerInput
          label="Código Integração"
          md={3}
          value={idIntegracaoItemCxaFrg}
          onChange={setIdIntegracaoItemCxaFrg}
        />
        <FormButton color="info" onClick={incluirItemCaixaFerragem} md={3}>
          Incluir
        </FormButton>
      </Row>
      <ItensCaixaFerragemGrid
        data={itensCaixaFerragem}
        removerItem={removerItemCaixaFerragem}
      />
    </ModalCadastro>
  );
};
