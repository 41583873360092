import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { Settings } from "react-feather";
import { useDispatch } from "react-redux";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { logoff } from "../auth/session";
import { ConfigModal } from "./configs/ConfigModal.tsx";

const NavbarComponent: FC = () => {
  const [configModalIsOpen, setConfigModalIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onLogout = async () => {
    await logoff();
    window.location.href = "/acesso/login/";
  };

  const getNickname = () => {
    let nickname = Cookies.get("nickname");
    if (nickname) {
      return nickname;
    } else {
      return "Não autenticado";
    }
  };

  const toggleConfigModal = () => setConfigModalIsOpen(!configModalIsOpen);

  useEffect(() => {
    const handleToggle = (event: KeyboardEvent) => {
      if (event.key === "q" && document.activeElement!.tagName === "BODY") {
        dispatch(toggleSidebar());
      }
    };

    document.addEventListener("keydown", handleToggle);
  }, [dispatch]);

  return (
    <>
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => dispatch(toggleSidebar())}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>

              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <span className="text-dark">{getNickname()}</span>
                </DropdownToggle>
              </span>

              <DropdownMenu right>
                <DropdownItem onClick={toggleConfigModal}>
                  Configurações
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={onLogout}>Sair</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
      <ConfigModal isOpen={configModalIsOpen} toggle={toggleConfigModal} />
    </>
  );
};

export default NavbarComponent;
