import React from "react";
import { useState } from "react";
import {
  ModalCadastro,
  TextInput,
  DragDropImagem,
  FormCheckbox,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Col, Row } from "reactstrap";

export const CadastroCorAcabamentoModal = ({ selected, notifyEvent }) => {
  const [descricao, setDescricao] = useState("");
  const [fotoBase64, setFotoBase64] = useState("");
  const [aceitaNegativo, setAceitaNegativo] = useState(true);
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setDescricao("");
    setFotoBase64("");
    setAceitaNegativo(true);
    setAtivo(true);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setAceitaNegativo(data.aceita_negativo);
    setFotoBase64(data.foto_base64);
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    const payload = {
      descricao: descricao,
      aceita_negativo: aceitaNegativo,
      foto_base64: fotoBase64,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Cor"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/cor"
      number="0009_1"
      deleteModalNumber="0009_2"
      selected={selected}
      notifyEvent={notifyEvent}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
        title: "Ativo",
      }}
    >
      <Row>
        <TextInput
          label="Descrição"
          value={descricao}
          onChange={setDescricao}
          maxLength={50}
        />
        <FormCheckbox
          label="Aceita Negativo"
          checked={aceitaNegativo}
          onChange={setAceitaNegativo}
        />
      </Row>
      <Row
        className="mt-4"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DragDropImagem
          width="66.66%"
          height="224px"
          value={fotoBase64}
          onChange={setFotoBase64}
        />
      </Row>
    </ModalCadastro>
  );
};
