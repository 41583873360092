import React from "react";
import { MoreDropdown, Table, TableCheck } from "../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const UsuarioGrid = ({
  dados,
  setSelected,
  alterarSenhaUsuario,
  definirPercentuaisUsuario,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    { dataField: "nome", text: "Nome", align: "left", headerAlign: "left" },
    { dataField: "login", text: "Login", align: "left", headerAlign: "left" },
    {
      dataField: "id_cidade__nome",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cidade),
    },
    {
      dataField: "markup",
      text: "Markup",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "oferecer_kit_economico",
      text: "Oferece Kit Eco.",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => (cell ? <TableCheck value={cell} /> : <></>),
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (_, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => alterarSenhaUsuario(row.id)}>
            Alterar Senha
          </DropdownItem>
          <DropdownItem onClick={() => definirPercentuaisUsuario(row.id)}>
            Definir Percentuais
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table data={dados} columns={columns} onSelect={setSelected} paginated />
  );
};
