import React from "react";
import { useState } from "react";
import {
  DragDropImagem,
  ModalCadastro,
  NumberInput,
  IntegerInput,
  TextInput,
  AsyncComboBox,
} from "../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../coreUtils";

export const CadastroFechoUnhaModal = ({ selected, notifyEvent }) => {
  const [nome, setNome] = useState("");
  const [fotoBase64, setFotoBase64] = useState("");
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(null);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setNome("");
    setFotoBase64("");
    setVlrCusto(0);
    setVlrPreco(0);
    setIdIntegracao(null);
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setFotoBase64(data.foto_base64);
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      foto_base64: fotoBase64,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Fecho Unha"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/fecho_unha"
      number="0008_1"
      deleteModalNumber="0008_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={80}
        />
      </Row>
      <Row>
        <NumberInput label="Custo" value={vlrCusto} onChange={setVlrCusto} />
        <NumberInput label="Preço" value={vlrPreco} onChange={setVlrPreco} />
        <IntegerInput
          label="Id Integração"
          hint="Código do Fecho Unha no sistema Skill"
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
          isClearable
        />
      </Row>
      <DragDropImagem
        width="100%"
        height="400px"
        divClassName="mt-2"
        value={fotoBase64}
        onChange={setFotoBase64}
      />
    </ModalCadastro>
  );
};
