import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Select from "react-select";
import { Col, FormGroup, Label, UncontrolledTooltip } from "reactstrap";
import { handleFocus } from "../coreUtils";

const ComboBox = forwardRef(
  (
    {
      md = 4,
      label,
      name,
      onChange,
      options = [],
      isSearchable,
      isClearable,
      isMulti,
      value,
      isDisabled,
      hint,
      clearOnDisable,
      autoFocus,
      tabOrder,
      id,
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [internalId] = useState(id ?? Math.floor(Math.random() * Date.now()));
    const selectRef = useRef();

    const clearValue = () => {
      selectRef.current.clearValue();
    };

    const setFocus = () => {
      selectRef.current.focus();
    };

    const setValue = (value) => {
      selectRef.current.setValue(value);
    };

    const setValueByID = (v) => {
      const opt = options.find((e) => e.value === v);
      selectRef.current.setValue(opt ?? null);
    };

    useImperativeHandle(ref, () => ({
      clearValue: () => clearValue(),
      setFocus: () => setFocus(),
      setValue: (v) => setValue(v),
    }));

    useEffect(() => {
      setValueByID(value);
    }, [value]);

    useEffect(() => {
      if (isDisabled && clearOnDisable) {
        clearValue();
      }
    }, [isDisabled, clearOnDisable]);

    const handleChange = (v, e) => {
      if ((v?.value ?? null) !== selectedValue) {
        setSelectedValue(v?.value ?? null);
        if (onChange) onChange(v?.value ?? null, v, e);
      }
    };

    return (
      <Col md={md}>
        <FormGroup onKeyDown={handleFocus}>
          <Label for={`react-select-${internalId}-input`}>
            {label}
            {hint && (
              <>
                <BsFillQuestionCircleFill
                  size={12}
                  className="ml-1 mb-1"
                  id="infoTooltip"
                  color="#47bac1"
                />
                <UncontrolledTooltip placement="right" target="infoTooltip">
                  {hint}
                </UncontrolledTooltip>
              </>
            )}
          </Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            name={name}
            onChange={handleChange}
            options={options}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={isMulti}
            isDisabled={isDisabled}
            ref={selectRef}
            placeholder="Selecione..."
            autoFocus={autoFocus}
            tabOrder={tabOrder}
            instanceId={internalId}
          />
        </FormGroup>
      </Col>
    );
  }
);

export default ComboBox;
