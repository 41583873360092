import React from "react";
import { useState } from "react";
import { Col, CustomInput, FormGroup, Label } from "reactstrap";

const RadioItem = ({ label = "", value, disabled, id, checked }) => (
  <CustomInput
    type="radio"
    label={label}
    value={value}
    id={id}
    inline
    checked={checked}
    disabled={disabled}
  />
);

export const RadioGroup = ({
  label,
  options = [],
  value,
  onChange = (v) => {},
  disabled,
  divClassName,
}) => {
  const [id] = useState(
    options.map((e) => "radio-" + Math.floor(Math.random() * Date.now()))
  );
  return (
    <Col md="auto" className={divClassName}>
      <FormGroup
        onChange={({ target }) => {
          onChange(target.value);
        }}
      >
        {label && <Label>{label}</Label>}
        <div>
          {options.map((item, index) => (
            <RadioItem
              id={id[index]}
              label={item.label}
              value={item.value}
              checked={value === item.value}
              disabled={disabled || item.disabled}
            />
          ))}
        </div>
      </FormGroup>
    </Col>
  );
};
