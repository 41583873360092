import { Document, Font } from "@react-pdf/renderer";
import React from "react";
import opensans_bold from "../../assets/fonts/OpenSans-Bold.ttf";
import opensans_bolditalic from "../../assets/fonts/OpenSans-BoldItalic.ttf";
import opensans from "../../assets/fonts/OpenSans-Regular.ttf";
import opensans_semibolditalic from "../../assets/fonts/OpenSans-SemiBoldItalic.ttf";
import { A4Page } from "./A4Page";
import { ReportFooter } from "./ReportFooter";
import { ReportHeader } from "./ReportHeader";

Font.register({
  family: "Open Sans",
  src: opensans,
});
Font.register({
  family: "Open Sans Bold",
  src: opensans_bold,
});
Font.register({
  family: "Open Sans Bold Italic",
  src: opensans_bolditalic,
});
Font.register({
  family: "Open Sans SemiBold Italic",
  src: opensans_semibolditalic,
});

export const PDFReport = ({
  children,
  number,
  dataIni,
  dataFim,
  orientation = "portrait",
  title,
  pageStyle = {},
}) => {
  return (
    <Document>
      <A4Page orientation={orientation} style={pageStyle}>
        <ReportHeader dataIni={dataIni} dataFim={dataFim} title={title} />
        {children}
        <ReportFooter reportNumber={number} />
      </A4Page>
    </Document>
  );
};
