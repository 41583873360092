import React from "react";
import { useState } from "react";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import {
  ComboBox,
  DragDropImagem,
  ModalCadastro,
  NumberInput,
  TextInput,
  IntegerInput,
  AsyncComboBox,
} from "../../../../components";
import { Row } from "reactstrap";

const tiposFecha = [
  { label: "Interna", value: "INTER" },
  { label: "Externa", value: "EXTER" },
  { label: "Banheiro", value: "BANHE" },
  { label: "Rolete", value: "ROLET" },
  { label: "Asa de Avião", value: "ASAAV" },
  { label: "Concha", value: "CONCH" },
  { label: "Fechdura Aux.", value: "FEAUX" },
];

export const CadastroFechaduraModal = ({ selected, notifyEvent }) => {
  const [nome, setNome] = useState("");
  const [fotoBase64, setFotoBase64] = useState("");
  const [tipoFecha, setTipoFecha] = useState(tiposFecha[0].value);
  const [referencia, setReferencia] = useState("");
  const [tamCaixa, setTamCaixa] = useState(0);
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setNome("");
    setFotoBase64("");
    setTipoFecha(tiposFecha[0].value);
    setReferencia("");
    setTamCaixa(0);
    setVlrCusto(0);
    setVlrPreco(0);
    setIdIntegracao(0);
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setFotoBase64(data.foto_base64 ?? "");
    setTipoFecha(data.tipo_fecha);
    setReferencia(data.referencia);
    setTamCaixa(parseFloat(data.tam_caixa));
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      foto_base64: fotoBase64,
      tipo_fecha: tipoFecha,
      referencia: referencia,
      tam_caixa: tamCaixa,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Fechadura"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/fechadura"
      number="0005_1"
      deleteModalNumber="0005_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={80}
        />
      </Row>
      <Row>
        <TextInput
          label="Referência"
          value={referencia}
          onChange={setReferencia}
          maxLength={50}
        />
        <ComboBox
          md={4}
          label="Tipo"
          options={tiposFecha}
          value={tipoFecha}
          onChange={setTipoFecha}
          hint={
            "Nas portas de Correr serão consideradas somente fechaduras " +
            "Asa de Avião e Concha"
          }
          isSearchable={false}
        />
        <NumberInput
          label="Tamanho da Caixa"
          value={tamCaixa}
          onChange={setTamCaixa}
        />
      </Row>
      <Row>
        <NumberInput label="Custo" value={vlrCusto} onChange={setVlrCusto} />
        <NumberInput label="Preço" value={vlrPreco} onChange={setVlrPreco} />
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
        />
      </Row>
      <DragDropImagem
        width="100%"
        height="400px"
        divClassName="mt-2"
        value={fotoBase64}
        onChange={setFotoBase64}
      />
    </ModalCadastro>
  );
};
