import React from "react";
import { formatNumber } from "../../../../coreUtils";
import { Table, TableDelete } from "../../../../components";

export const ItensCaixaFerragemGrid = ({ data, removerItem }) => {
  const columns = [
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_unit",
      text: "Preço Unitário",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "id_integracao",
      text: "ID Integração",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "",
      dummy: true,
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (_, row) => {
        return <TableDelete onClick={() => removerItem(row.uuid)} />;
      },
    },
  ];
  return (
    <Table
      data={data}
      columns={columns}
      keyField="uuid"
      hideSelectColumn
      paginated={false}
    />
  );
};
