import { api } from "../api";

class FilterServiceV2 {
  static fetch(table, filters) {
    return api
      .get(
        `/cadastro/concat/${table}/${
          filters !== undefined ? `?${filters}` : ""
        }`
      )
      .then((fetchData) => {
        const checkErr = fetchData.data;
        if (checkErr.success) {
          return fetchData.data.res;
        } else {
          const { data } = fetchData;
          throw `${data.msg} - Erro: ${data.id_err}`;
        }
      })
      .catch(() => {
        throw "Erro inesperado!";
      });
  }
}

export default FilterServiceV2;
