import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroTrilhoPortaModal } from "./components/CadastroTrilhoPortaModal";
import { TrilhoPortaGrid } from "./components/TrilhoPortaGrid";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../coreUtils";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../components";
import { apiGet } from "../../../api";

export const TrilhoPorta = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p) => {
    const ret = await apiGet("/cadastro/trilho_porta/listar", { pista: p });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer title="Cadastro de Trilho de Porta" number="0008">
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroTrilhoPortaModal
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <TrilhoPortaGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
