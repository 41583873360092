import { View } from "@react-pdf/renderer";
import React from "react";
import { TextRow } from "./TextRow";

export const ReportDataListMultiRow = ({
  columns = [[]],
  data = [],
  stripped,
  fixedHeader,
  headerStyle,
  subHeaderStyle,
  rowStyle,
  subRowStyle,
}) => {
  return (
    <View>
      <View style={headerStyle} fixed={fixedHeader}>
        {columns.map((row) => (
          <TextRow
            style={subHeaderStyle}
            columns={row.map((field) => {
              return {
                col: field.col,
                text: field.text,
                style: {
                  textAlign: field.align,
                  fontFamily: "Open Sans Bold",
                  marginTop: "auto",
                  ...field.headerStyle,
                },
              };
            })}
          />
        ))}
      </View>
      {data.map((item, index) => (
        <View
          wrap={false}
          style={{
            backgroundColor: stripped
              ? index % 2 === 0
                ? "white"
                : "#DDD"
              : "",
            ...rowStyle,
          }}
        >
          {columns.map((row) => (
            <TextRow
              style={subRowStyle}
              columns={row.map((col) => {
                return {
                  col: col.col,
                  text: col.formatter
                    ? col.formatter(item[col.dataField], item, index)
                    : item[col.dataField],
                  style: { textAlign: col.align, ...col.cellStyle },
                };
              })}
            />
          ))}
        </View>
      ))}
    </View>
  );
};
