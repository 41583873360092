import React, { useEffect } from "react";
import { useState } from "react";
import {
  ComboBox,
  IntegerInput,
  ModalCadastro,
  NumberInput,
  RadioGroup,
  AsyncComboBox,
} from "../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS, tiposMaterial } from "../../../../coreUtils";
import FormLabel from "../../../../components/FormLabel";
import { toastr } from "react-redux-toastr";

const largurasMarcos = [
  { label: "70mm", value: 70 },
  { label: "90mm", value: 90 },
  { label: "115mm", value: 115 },
  { label: "140mm", value: 140 },
  { label: "160mm", value: 160 },
  { label: "190mm", value: 190 },
  { label: "230mm", value: 230 },
];

const rebaixosMarco = [
  { label: "36mm", value: 36 },
  { label: "41mm", value: 41 },
];

const tiposMarco = [
  { label: "Normal com Rebaixo", value: "NORM" },
  { label: "Passagem", value: "PASG" },
];

export const CadastroMarcoModal = ({ selected, notifyEvent }) => {
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [ativo, setAtivo] = useState(true);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [comprimento, setComprimento] = useState(null);
  const [largura, setLargura] = useState(null);
  const [rebaixo, setRebaixo] = useState(null);
  const [idCantoneira, setIdCantoneira] = useState(null);
  const [qtdCantoneira, setQtdCantoneira] = useState(2);
  const [idCor, setIdCor] = useState(null);
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [tipoMarco, setTipoMarco] = useState("NORM");
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setTipoMaterial(tiposMaterial[0].value);
    setComprimento(null);
    setLargura(null);
    setRebaixo(null);
    setVlrCusto(0);
    setVlrPreco(0);
    setIdCantoneira(null);
    setQtdCantoneira(2);
    setIdCor(null);
    setAtivo(true);
    setIdIntegracao(0);
    setTipoMarco("NORM");
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setTipoMaterial(data.tipo_material);
    setComprimento(parseFloat(data.comprimento));
    setLargura(parseInt(data.espessura));
    setRebaixo(parseFloat(data.rebaixo));
    setIdCantoneira(data.id_cantoneira);
    setQtdCantoneira(data.qtd_cantoneira);
    setIdCor(data.id_cor);
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setAtivo(data.ativo);
    setIdIntegracao(data.id_integracao);
    setTipoMarco(data.tipo_marco);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    if (!idCantoneira) {
      toastr.warning("Atenção", "Por favor, selecione a Cantoneira.");
      return false;
    }
    if (!idCor) {
      toastr.warning("Atenção", "Por favor, selecione a Cor.");
      return false;
    }

    const payload = {
      tipo_material: tipoMaterial,
      comprimento: comprimento,
      espessura: largura,
      rebaixo: rebaixo,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      id_cantoneira: idCantoneira ?? 0,
      qtd_cantoneira: qtdCantoneira ?? 0,
      id_cor: idCor ?? 0,
      ativo: ativo,
      id_integracao: idIntegracao,
      tipo_marco: tipoMarco,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  const onOpen = (action) => setAction(action);

  useEffect(() => {
    setRebaixo(null);
  }, []);

  return (
    <ModalCadastro
      title="Jogo de Marco"
      size="lg"
      onOpen={onOpen}
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase="/cadastro/marco"
      number="0006_1"
      deleteModalNumber="0006_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <RadioGroup
          label="Material"
          options={tiposMaterial}
          value={tipoMaterial}
          onChange={setTipoMaterial}
        />
        <RadioGroup
          divClassName="ml-4"
          label="Tipo de Marco"
          options={tiposMarco}
          value={tipoMarco}
          onChange={setTipoMarco}
        />
      </Row>
      <Row>
        <NumberInput
          label="Comprimento"
          value={comprimento}
          onChange={setComprimento}
          decimalPlaces={0}
          align="center"
          placeholder="Em Milímetros"
          md={5}
        />
        <FormLabel md={1}>{`${comprimento / 1000}m`}</FormLabel>
        <ComboBox
          md={3}
          options={largurasMarcos}
          label="Largura"
          value={largura}
          isSearchable={false}
          onChange={setLargura}
        />
        {tipoMarco === "NORM" && (
          <ComboBox
            md={3}
            options={rebaixosMarco}
            label="Rebaixo"
            value={rebaixo}
            isSearchable={false}
            onChange={setRebaixo}
          />
        )}
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          label="Cantoneira"
          isConcatField
          defaultOptions
          concatModelName="cantoneira"
          value={idCantoneira}
          onChange={setIdCantoneira}
        />
        <IntegerInput
          label="Quantidade Cantoneira"
          value={qtdCantoneira}
          onChange={setQtdCantoneira}
          md={4}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={4}
          label="Cor"
          isConcatField
          defaultOptions
          concatModelName="cor_acabamento"
          value={idCor}
          onChange={setIdCor}
        />
        <NumberInput label="Custo" value={vlrCusto} onChange={setVlrCusto} />
        <NumberInput label="Preço" value={vlrPreco} onChange={setVlrPreco} />
      </Row>
      <Row>
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={8}
        />
      </Row>
    </ModalCadastro>
  );
};
