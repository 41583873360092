import React from "react";
import { formatNumber } from "../../../../coreUtils";
import { DataTable } from "../../../../components";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "vlr_custo",
    text: "Custo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_preco",
    text: "Preço",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const ColaGrid = ({ data, setSelected }) => {
  return (
    <DataTable data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
