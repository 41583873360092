import { Users as UsersIcon } from "react-feather";
import ResetPassword from "../../pages/auth/RedefinirSenha";
import SignIn from "../../pages/auth/SignIn";

const authRoutes = {
  path: "/acesso/",
  name: "Acesso",
  icon: UsersIcon,
  children: [
    {
      path: "/acesso/redefinir-senha",
      name: "Resetar Senha",
      component: ResetPassword,
    },
    {
      path: "/acesso/*",
      name: "Login de Acesso",
      component: SignIn,
    },
  ],
};

export default authRoutes;
