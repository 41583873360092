import React from "react";
import { Trash2 } from "react-feather";
import { IconButton } from "./IconButton";

export const TableDelete = ({
  onClick,
  disabled,
  color = "#db3d3d",
  disabledHint,
  hint,
  id,
  className,
  size = 16,
  tooltipPlacement,
  style,
}) => (
  <IconButton
    icon={Trash2}
    onClick={onClick}
    className={className}
    size={size}
    color={color}
    disabled={disabled}
    disabledHint={disabledHint}
    hint={hint}
    id={id}
    tooltipPlacement={tooltipPlacement}
    style={style}
  />
);
