import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoImprimir,
  BotaoPesquisar,
  PageContainer,
  TextInput,
} from "../../../components";
import { ImpressaoEtiquetasGrid } from "./components/ImpressaoEtiquetasGrid";
import PrintingService from "../../../services/PrintingService";
import { v4 as uuidv4 } from "uuid";
import { toastr } from "react-redux-toastr";
import { etiquetaPortaItemIndividual } from "./layouts/itemIndividual";
import { imprimirEtiquetasPortaCorrer } from "./layouts/portasCorrer";
import { imprimirEtiquetasPortaAbrir } from "./layouts/portasAbrir";
import { apiGet } from "../../../api";
import { etiquetaMarco } from "./layouts/marco";

export const ImpressaoEtiquetasPedidos = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingPesquisa, setLoadingPesquisa] = useState(false);
  const [loadingImpressaoEtiquetas, setLoadingImpressaoEtiquetas] =
    useState(false);
  // Filtros
  const [nroPedido, setNroPedido] = useState(null);

  // Impressoras
  const [nomeImpressoraEtqAzul, setNomeImpressoraEtqAzul] = useState(null);
  const [nomeImpressoraEtqVerde, setNomeImpressoraEtqVerde] = useState(null);
  const [nomeImpressoraEtqLaranja, setNomeImpressoraEtqLaranja] =
    useState(null);
  const [nomeImpressoraEtqBranco, setNomeImpressoraEtqBranco] = useState(null);
  const [nomeImpressoraEtqRoxo, setNomeImpressoraEtqRoxo] = useState(null);

  const buscarParametros = async () => {
    const ret = await apiGet("pedido/params_etiquetas/buscar/");
    if (ret) {
      setNomeImpressoraEtqAzul(ret.nome_impressora_etq_azul);
      setNomeImpressoraEtqVerde(ret.nome_impressora_etq_verde);
      setNomeImpressoraEtqLaranja(ret.nome_impressora_etq_laranja);
      setNomeImpressoraEtqBranco(ret.nome_impressora_etq_branco);
      setNomeImpressoraEtqRoxo(ret.nome_impressora_etq_roxo);
    }
  };

  useEffect(() => {
    buscarParametros();
  }, []);

  const carregarDados = async () => {
    setLoadingPesquisa(true);
    setDados([]);
    try {
      const ret = await apiGet("pedido/adm/listar_impressao_etiqueta/", {
        num_pedido: nroPedido,
      });
      if (ret) {
        setDados(ret);
      }
    } finally {
      setLoadingPesquisa(false);
    }
  };

  const imprimirEtiquetas = async () => {
    setLoadingImpressaoEtiquetas(true);
    try {
      const configsImpressoras = {
        azul: nomeImpressoraEtqAzul,
        verde: nomeImpressoraEtqVerde,
        laranja: nomeImpressoraEtqLaranja,
        branco: nomeImpressoraEtqBranco,
        roxo: nomeImpressoraEtqRoxo,
      };
      if (["KABR", "KCOR"].includes(selected.tipo_pedido)) {
        const ret = await apiGet(`/pedido/buscar_dados/${selected.id}/`);

        if (ret) {
          const itensPed = ret.itens;

          const portasAbrir = itensPed.filter(
            (e) => e.modo_abertura === "Abrir"
          );
          if (portasAbrir.length > 0) {
            await imprimirEtiquetasPortaAbrir(
              portasAbrir.map((p) => {
                return {
                  uuid: uuidv4(),
                  seq: p.item,
                  nro_pedido: selected.id,
                  tipo: "ABRIR",
                  cliente: ret.nome_usuario, // TODO: Buscar dados do Cliente
                  cliente_final: "", // TODO: Buscar dados do cliente
                  nome_porta: p.descricao,
                  largura_porta: p.fol_largura_pronta,
                  altura_porta: p.fol_altura_pronta,
                  marco: p.compr_marco,
                  qtd_vista_regulavel: p.qtd_vista_regulavel,
                  qtd_vista_fixa: p.qtd_vista_fixa,
                  largura_vista: p.largura_vista,
                  espessura_vista: p.compr_vista,
                  cor: p.nome_cor_acabamento,
                  material: p.tipo_material,
                  lado: p.sigla_lado_abert,
                  fechadura: p.nome_fechadura,
                  referencia_fecha: p.ref_fechadura,
                  tipo_fecha: "",
                };
              }),
              "ZPL",
              configsImpressoras
            );
          }

          const portasCorrer = itensPed.filter(
            (e) => e.modo_abertura === "De Correr"
          );
          if (portasCorrer.length > 0) {
            await imprimirEtiquetasPortaCorrer(
              portasCorrer.map((p) => {
                return {
                  uuid: uuidv4(),
                  seq: p.item,
                  nro_pedido: selected.id,
                  tipo: "CORRER",
                  cliente: ret.nome_usuario, // TODO: Buscar dados do Cliente
                  cliente_final: "", // TODO: Buscar dados do cliente
                  nome_porta: p.descricao,
                  largura_porta: p.fol_largura_pronta,
                  altura_porta: p.fol_altura_pronta,
                  marco: p.compr_marco,
                  qtd_vista_regulavel: p.qtd_vista_regulavel,
                  qtd_vista_fixa: p.qtd_vista_fixa,
                  largura_vista: p.largura_vista,
                  espessura_vista: p.compr_vista,
                  cor: p.nome_cor_acabamento,
                  material: p.tipo_material,
                  lado: p.sigla_lado_abert,
                  fechadura: p.nome_fechadura,
                  referencia_fecha: p.ref_fechadura,
                  tipo_fecha: "",
                };
              }),
              "ZPL",
              configsImpressoras
            );
          }

          const etiquetaPassagem = await etiquetaMarco(
            itensPed.filter((e) => ["MARCO PASSAGEM"].includes(e.tipo))
          );
          if (etiquetaPassagem.length > 0) {
            await PrintingService.printLabel(
              etiquetaPassagem,
              configsImpressoras.laranja
            );
            await PrintingService.printLabel(
              etiquetaPassagem,
              configsImpressoras.verde
            );
          }
        }
      } else {
        const ret = await apiGet(`/pedido/componentes/listar/${selected.id}/`);

        if (ret) {
          const itensPed = ret.itens;

          const etiquetaPassagem = await etiquetaMarco(
            itensPed.filter((e) => ["MARC"].includes(e.tipo_componente))
          );
          if (etiquetaPassagem.length > 0) {
            await PrintingService.printLabel(
              etiquetaPassagem,
              configsImpressoras.laranja
            );
            await PrintingService.printLabel(
              etiquetaPassagem,
              configsImpressoras.verde
            );
          }
          // const etiquetaFolhaUsinada = await etiquetaPortaItemIndividual(
          //   itensPed.filter(
          //     (e) =>
          //       ![("CORRER", "MARCO PASSAGEM", "ABRIR")].includes(e.tipo) &&
          //       e.usinagem === "SIM"
          //   )
          // );
          // if (etiquetaFolhaUsinada.length > 0) {
          //   await PrintingService.printLabel(
          //     etiquetaFolhaUsinada,
          //     "Argox OS-214 plus series PPLA"
          //   );
          // }

          const folha = await etiquetaPortaItemIndividual(
            itensPed.filter((e) => ["FOLH"].includes(e.tipo_componente))
          );
          if (folha.length > 0) {
            await PrintingService.printLabel(folha, configsImpressoras.azul);
          }
        }
      }
    } catch (e) {
      debugger;
      toastr.error("Erro ao Imprimir Etiquetas", e);
    } finally {
      setLoadingImpressaoEtiquetas(false);
    }
  };

  return (
    <PageContainer title="Impressão de Etiquetas de Pedidos" number="0001">
      <Card body>
        <Row className="mb-2">
          <BotaoPesquisar
            onClick={() => carregarDados()}
            loading={loadingPesquisa}
          />
          <BotaoImprimir
            onClick={imprimirEtiquetas}
            disabled={selected === null}
            text="Imprimir Etiquetas Selecionadas"
            loading={loadingImpressaoEtiquetas}
          />
          <TextInput label="Nº Pedido" onChange={setNroPedido} md={2} />
        </Row>
        <ImpressaoEtiquetasGrid
          data={dados}
          setSelected={(_, checked, row) => {
            if (checked) {
              setSelected(row);
            } else {
              setSelected(null);
            }
          }}
        />
      </Card>
    </PageContainer>
  );
};
