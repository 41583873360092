import React, { Fragment } from "react";

const ModalHeaderActionCheckbox = ({ isActive, toggleCheck, label, id }) => {
  return (
    <Fragment>
      <input
        type="checkbox"
        id={id || ""}
        className="position-relative mr-2 ml-2"
        style={{ top: "1.8px" }}
        checked={isActive}
        onClick={toggleCheck}
      />
      <label style={{ fontSize: "14px" }}>{label}</label>
    </Fragment>
  );
};

export default ModalHeaderActionCheckbox;
