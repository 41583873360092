import React from "react";
import { useState } from "react";
import {
  ModalCadastro,
  NumberInput,
  TextInput,
  IntegerInput,
} from "../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../coreUtils";

export const CadastroUsinagemEspecialModal = ({ selected, notifyEvent }) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setVlrCusto(0);
    setVlrPreco(0);
    setIdIntegracao(0);
  };

  const fetchData = (data) => {
    setAtivo(data.ativo);
    setNome(data.nome);
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdIntegracao(data.id_integracao);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      ativo: ativo,
      id_integracao: idIntegracao,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Usinagem Especial"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase="/cadastro/usinagens_especiais"
      number="0004_1"
      deleteModalNumber="0004_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={80}
        />
      </Row>
      <Row>
        <NumberInput label="Custo" value={vlrCusto} onChange={setVlrCusto} />
        <NumberInput label="Preço" value={vlrPreco} onChange={setVlrPreco} />
        <IntegerInput
          label="Código Integração"
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
    </ModalCadastro>
  );
};
