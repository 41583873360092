import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import { GerenciamentoPedidosGrid } from "./components/GerenciamentoPedidosGrid";
import {
  BotaoPesquisar,
  PageContainer,
  FiltroPeriodoDatas,
  FormButton,
  IntegerInput,
  ComboBox,
  AsyncComboBox,
  ModalBase,
} from "../../../components";
import { apiGet, apiPost } from "../../../api";
import { PedidoPDF } from "./pdf/PedidoPDF";
import { printReport } from "../../../coreUtils";
import moment from "moment";

const statusPedido = [
  { label: "Em Digitação", value: "DIG" },
  { label: "Aguardando Confirmação", value: "AGC" },
  { label: "Devolvido para Ajuste", value: "DPA" },
  { label: "Em Produção", value: "EMP" },
];

export const GerenciamentoPedidos = () => {
  const [numPedido, setNumPedido] = useState(null);
  const [status, setStatus] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [idUsuario, setIdUsuario] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImpPedido, setLoadingImpPedido] = useState(false);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [modalConfirmarPedidoOpen, setModalConfirmarPedidoOpen] =
    useState(false);
  const [loadingConfirmarPedido, setLoadingConfirmarPedido] = useState(false);

  const buscarDados = async () => {
    const params = {
      status: status,
      num_pedido: numPedido,
      data_ini: moment(dataIni).format("YYYY-MM-DD"),
      data_fim: moment(dataFim).format("YYYY-MM-DD"),
      id_usuario: idUsuario,
    };
    const ret = await apiGet("/pedido/adm/listar/", params);
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const imprimirPedido = async () => {
    setLoadingImpPedido(true);
    const ret = await apiGet(`/pedido/impressao/buscar/${selected}/`);
    if (ret) {
      await printReport(<PedidoPDF data={ret} />, "0001");
    }
    setLoadingImpPedido(false);
  };

  const gerarVendaSkill = async () => {
    setLoadingConfirmarPedido(true);
    try {
      const ret = await apiPost("pedido/confirmar_pedido/", {
        id_pedido: selected,
      });
      if (ret) {
        setModalConfirmarPedidoOpen(false);
        carregarDados();
      }
    } finally {
      setLoadingConfirmarPedido(false);
    }
  };

  return (
    <PageContainer title="Pedidos" number="0001">
      <ModalBase
        isOpen={modalConfirmarPedidoOpen}
        title="Confirmar Pedido"
        size="sm"
        toggle={() => setModalConfirmarPedidoOpen(false)}
        loadingConfirm={loadingConfirmarPedido}
        onConfirm={gerarVendaSkill}
      >
        Após a confirmação do pedido, o sistema irá:
        <ul>
          <li>Atualiza o estoque</li>
          <li>Gerar venda e pendência financeira no sistema skillsoft</li>
          <li>Liberar o pedido para impressão de etiquetas</li>
        </ul>
      </ModalBase>
      <Card body>
        <Row>
          <IntegerInput
            label="Nº do Pedido"
            value={numPedido}
            onChange={setNumPedido}
            md={2}
          />
          <ComboBox
            label="Status do Pedido"
            md={2}
            options={statusPedido}
            value={status}
            onChange={setStatus}
            isClearable
            isSearchable={false}
          />
          <AsyncComboBox
            concatModelName="usuario"
            isConcatField
            defaultOptions
            label="Usuário"
            isClearable
            isSearchable
            onChange={setIdUsuario}
            md={2}
          />
          <FiltroPeriodoDatas
            onChange={(dIni, dFim) => {
              setDataIni(dIni);
              setDataFim(dFim);
            }}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormButton
            md="auto"
            loading={loadingImpPedido}
            onClick={imprimirPedido}
            color="secondary"
            disabled={selected == null}
          >
            Imprimir
          </FormButton>
          <FormButton
            md="auto"
            onClick={() => {
              setModalConfirmarPedidoOpen(true);
            }}
            color="info"
            disabled={selectedStatus !== "AGC"}
          >
            Confirmar Pedido
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <GerenciamentoPedidosGrid
          data={dados}
          setSelected={(s, row, _) => {
            setSelected(s);
            setSelectedStatus(row?.status);
          }}
        />
      </Card>
    </PageContainer>
  );
};
