import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { apiGet, apiPut } from "../../../api";
import TextInput from "../../TextInput";
import FormButton from "../../FormButton";

const AbaConfigEtiquetas = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingConfirmar, setLoadingConfirmar] = useState<boolean>(false);
  const [nomeImpressoraEtqAzul, setNomeImpressoraEtqAzul] = useState<
    string | null
  >();
  const [nomeImpressoraEtqBranco, setNomeImpressoraEtqBranco] = useState<
    string | null
  >();
  const [nomeImpressoraEtqLaranja, setNomeImpressoraEtqLaranja] = useState<
    string | null
  >();
  const [nomeImpressoraEtqVerde, setNomeImpressoraEtqVerde] = useState<
    string | null
  >();
  const [nomeImpressoraEtqRoxo, setNomeImpressoraEtqRoxo] = useState<
    string | null
  >();

  const buscarDados = async () => {
    setLoading(true);
    try {
      const ret = await apiGet("pedido/params_etiquetas/buscar/");
      if (ret) {
        setNomeImpressoraEtqAzul(ret.nome_impressora_etq_azul);
        setNomeImpressoraEtqBranco(ret.nome_impressora_etq_branco);
        setNomeImpressoraEtqLaranja(ret.nome_impressora_etq_laranja);
        setNomeImpressoraEtqVerde(ret.nome_impressora_etq_verde);
        setNomeImpressoraEtqRoxo(ret.nome_impressora_etq_roxo);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const confirmarConfigsEtiquetas = async () => {
    setLoadingConfirmar(true);
    try {
      const payload = {
        nome_impressora_etq_azul: nomeImpressoraEtqAzul,
        nome_impressora_etq_laranja: nomeImpressoraEtqLaranja,
        nome_impressora_etq_verde: nomeImpressoraEtqVerde,
        nome_impressora_etq_branco: nomeImpressoraEtqBranco,
        nome_impressora_etq_roxo: nomeImpressoraEtqRoxo,
      };

      const ret = await apiPut("pedido/params_etiquetas/alterar/", payload);
    } finally {
      setLoadingConfirmar(false);
    }
  };

  return (
    <>
      <Row className="mb-2">
        <TextInput
          label="Nome Impressora - Etiqueta Azul"
          value={nomeImpressoraEtqAzul}
          onChange={setNomeImpressoraEtqAzul}
        />
      </Row>
      <Row className="mb-2">
        <TextInput
          label="Nome Impressora - Etiqueta Branca"
          value={nomeImpressoraEtqBranco}
          onChange={setNomeImpressoraEtqBranco}
        />
      </Row>
      <Row className="mb-2">
        <TextInput
          label="Nome Impressora - Etiqueta Verde"
          value={nomeImpressoraEtqVerde}
          onChange={setNomeImpressoraEtqVerde}
        />
      </Row>
      <Row className="mb-2">
        <TextInput
          label="Nome Impressora - Etiqueta Laranja"
          value={nomeImpressoraEtqLaranja}
          onChange={setNomeImpressoraEtqLaranja}
        />
      </Row>
      <Row className="mb-2">
        <TextInput
          label="Nome Impressora - Etiqueta Lilás"
          value={nomeImpressoraEtqRoxo}
          onChange={setNomeImpressoraEtqRoxo}
        />
      </Row>
      <Row className="d-flex mt-3">
        <FormButton
          md="auto"
          padded={false}
          loading={loadingConfirmar}
          onClick={confirmarConfigsEtiquetas}
          color="success"
          divClassName="ml-auto"
        >
          Confirmar
        </FormButton>
      </Row>
    </>
  );
};

export default AbaConfigEtiquetas;
