import { GerenciamentoPedidos } from "../../pages/pedidos/gerenciamento/GerenciamentoPedidos";
import { ImpressaoEtiquetasManual } from "../../pages/pedidos/impressao_etiquetas/ImpressaoEtiquetasManual";
import { ImpressaoEtiquetasPedidos } from "../../pages/pedidos/impressao_etiquetas/ImpressaoEtiquetasPedidos";

const pedidosRoutes = {
  path: "/pedidos",
  name: "Pedidos",
  children: [
    {
      path: "/pedidos/gerenciamento/",
      name: "Confirmação",
      component: GerenciamentoPedidos,
    },
    {
      path: "/pedidos/impressao_etiquetas/",
      name: "Etiquetas",
      component: ImpressaoEtiquetasPedidos,
    },
    {
      path: "/pedidos/impressao_manual_etiquetas/",
      name: "Etiqueta Manual",
      component: ImpressaoEtiquetasManual,
    },
  ],
};

export default pedidosRoutes;
