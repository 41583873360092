import React from "react";
import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import cadastroRoutes from "../routes/modules/cadastro";

const search = (tree, concatModelName) => {
  if (tree.concatModelName === concatModelName) {
    return tree;
  } else if (!tree.children) {
    return null;
  }

  for (const child of tree.children) {
    const found = search(child, concatModelName);

    if (found) {
      return found;
    }
  }
};

export const ConcatShortcut = ({ concatModelName, setSelected }) => {
  const [internalId] = useState("cs-" + Math.floor(Math.random() * Date.now()));
  const [shortcut] = useState(search(cadastroRoutes, concatModelName));

  const onClick = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(shortcut.path);

    cadastro.window.parameters = JSON.stringify({ id: id });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      setSelected(event.data.selected);

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  return concatModelName && shortcut ? (
    <>
      <div id={internalId} className="react-select-shortcut" onClick={onClick}>
        <shortcut.shortcutIcon size={20} color="white" />
      </div>
      <UncontrolledTooltip target={internalId}>
        Clique para acessar o Cadastro de {shortcut.name}
      </UncontrolledTooltip>
    </>
  ) : (
    <></>
  );
};
