import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroFechaduraModal } from "./components/CadastroFechaduraModal";
import { FechaduraGrid } from "./components/FechaduraGrid";
import { apiGet } from "../../../api";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../coreUtils";
import {
  BotaoPesquisar,
  ComboBox,
  PageContainer,
  SearchInput,
} from "../../../components";

const tiposFecha = [
  { label: "Interna", value: "INTER" },
  { label: "Externa", value: "EXTER" },
  { label: "Banheiro", value: "BANHE" },
  { label: "Rolete", value: "ROLET" },
];

export const Fechadura = () => {
  const [pista, setPista] = useState("");
  const [tipoFechadura, setTipoFechadura] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p) => {
    const ret = await apiGet("/cadastro/fechadura/listar", {
      pista: p,
      tipo_fechadura: tipoFechadura,
    });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer title="Cadastro de Fechaduras" number="0005">
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <ComboBox
            md={2}
            label="Tipo"
            options={tiposFecha}
            value={tipoFechadura}
            onChange={setTipoFechadura}
            isClearable
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroFechaduraModal
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <FechaduraGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
