import React, { useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { MoreVertical, MoreHorizontal } from "react-feather";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

export const MoreDropdown = ({
  children,
  onToggle,
  disabled,
  disabledHint = "Sem ações disponíveis",
  horizontal = false,
}) => {
  const [id] = useState("more-" + Math.floor(Math.random() * Date.now()));
  return !disabled ? (
    <UncontrolledDropdown direction="left" onToggle={onToggle}>
      <DropdownToggle
        style={{
          background: "none",
          border: "none",
          marginLeft: "auto",
          padding: 0,
          cursor: "pointer",
        }}
      >
        {horizontal ? (
          <MoreHorizontal
            size={18}
            color="black"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <MoreVertical size={18} color="black" style={{ cursor: "pointer" }} />
        )}
      </DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <>
      <MoreVertical size={18} color="silver" id={id} />
      <UncontrolledTooltip target={id}>{disabledHint}</UncontrolledTooltip>
    </>
  );
};
